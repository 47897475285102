import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTablesModule } from 'angular-datatables';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgSelect2Module } from 'ng-select2';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { TagifyModule } from 'ngx-tagify'

import { ActivityLogComponent } from '@/components/activity-log/activity-log.component';
import { CommentsComponent } from '@/components/comments/comments.component';
import { DryinInspectionAddComponent } from '@/views/inspections/dryin-inspection-add/dryin-inspection-add.component';
import { DryinInspectionViewComponent } from '@/views/inspections/dryin-inspection-view/dryin-inspection-view.component';
import { ShingleInspectionAddComponent } from '@/views/inspections/shingle-inspection-add/shingle-inspection-add.component';
import { ShingleInspectionViewComponent } from '@/views/inspections/shingle-inspection-view/shingle-inspection-view.component';
import { SafetyInspectionAddComponent } from '@/views/inspections/safety-inspection-add/safety-inspection-add.component';
import { SafetyInspectionViewComponent } from '@/views/inspections/safety-inspection-view/safety-inspection-view.component';

// Test the import of job types
import { BuilderJobsDatatableComponent } from '@/components/datatable/builder-jobs-datatable/builder-jobs-datatable.component';
import { WarrantyJobsDatatableComponent } from '@/components/datatable/warranty-jobs-datatable/warranty-jobs-datatable.component';
import { RepairJobsDatatableComponent } from '@/components/datatable/repair-jobs-datatable/repair-jobs-datatable.component';
import { ReroofJobsDatatableComponent } from '@/components/datatable/reroof-jobs-datatable/reroof-jobs-datatable.component';

// export const options: Partial<IConfig> | (() => Partial<IConfig>) = null

@NgModule({
	declarations: [
		ActivityLogComponent,
		CommentsComponent,
		DryinInspectionAddComponent,
		DryinInspectionViewComponent,
		ShingleInspectionAddComponent,
		ShingleInspectionViewComponent,
		SafetyInspectionAddComponent,
		SafetyInspectionViewComponent,
		BuilderJobsDatatableComponent,
		WarrantyJobsDatatableComponent,
		RepairJobsDatatableComponent,
		ReroofJobsDatatableComponent,
	],
	imports: [
		NgxMaskModule.forRoot(),
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelect2Module,
		NgbModule,
		FullCalendarModule,
		DataTablesModule,
		NgxPaginationModule,
		SignaturePadModule,
		TagifyModule.forRoot(),
	],
	exports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelect2Module,
		NgbModule,
		NgxMaskModule,
		FullCalendarModule,
		DataTablesModule,
		NgxPaginationModule,
		TagifyModule,
		SignaturePadModule,
		ActivityLogComponent,
		CommentsComponent,
		DryinInspectionAddComponent,
		DryinInspectionViewComponent,
		ShingleInspectionAddComponent,
		ShingleInspectionViewComponent,
		SafetyInspectionAddComponent,
		SafetyInspectionViewComponent,
		BuilderJobsDatatableComponent,
		WarrantyJobsDatatableComponent,
		RepairJobsDatatableComponent,
		ReroofJobsDatatableComponent
	]
})
export class JobModule { }

export {
	DryinInspectionAddComponent,
	DryinInspectionViewComponent,
	ShingleInspectionAddComponent,
	ShingleInspectionViewComponent,
	SafetyInspectionAddComponent,
	SafetyInspectionViewComponent,
}
