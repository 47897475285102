import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { JobsService } from '@/services/jobs/jobs.service';
import { BuildersService } from '@/services/builders/builders.service';
import { CommunitiesService } from '@/services/communities/communities.service';
import { UserService } from '@/services/user/user.service';
import { InspectionsService } from '@/services/inspections/inspections.service';
import { FilesService } from '@/services/files/files.service';
import { AlertService } from '@/services/alert/alert.service';
import { RoleService } from '@/services/role/role.service';

import { BuilderJob } from '@/models/builder-job';
import { Community } from '@/models/community';
import { User } from '@/models/user';
import { Builder } from '@/models/builder';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { isArray } from 'util';

import { Select2OptionData } from 'ng-select2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shingle-inspection-add',
  templateUrl: './shingle-inspection-add.component.html',
  styleUrls: ['./shingle-inspection-add.component.scss']
})
export class ShingleInspectionAddComponent implements OnInit {
  @ViewChild('inspectionTable')
  inspectionTable: ElementRef;

  @ViewChild(SignaturePad)
  signaturePad: SignaturePad;

  @ViewChild('signatureDiv')
  signatureDiv: ElementRef;

  jobID            : string
  jobType          : string
  job              : BuilderJob
  community        : Community
  builder          : Builder
	crewLeader       : User
	crewLeaders      : Observable<Array<Select2OptionData>>
  checklistForm    : FormGroup
  otherEntries     : FormArray
  uploadError      : string
  signatureBaseURL : string

  builderFiles   = [];
  uploadResponse = { status: '', message: '', filePath: '' }
  submitted      = false
  selectOptions  = [
    { name: "N/A", value: "0" },
    { name: "No", value: "1" },
    { name: "Yes", value: "2" },
  ]
  signaturePadOptions: Object = {
    'minWidth': 3,
    'canvasWidth': 500,
    'canvasHeight': 200,
	}
	select2Options  = {
		theme: "bootstrap4",
		width: "100%"
	}

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private jobService: JobsService,
    private builderService: BuildersService,
    private communityService: CommunitiesService,
    private userService: UserService,
		public roleService: RoleService,
		private modalService: NgbModal,
    private inspectionsService: InspectionsService,
    private filesService: FilesService,
    private alertService: AlertService,
  ) {
    this.jobID = this.route.snapshot.paramMap.get("id")
  }

  ngAfterViewInit() {
    if( this.signaturePad ){
			this.signaturePad.set('minWidth', 3)
			this.setCanvasWidth()
			this.signaturePad.clear()
		}
  }

  clearSignature() {
    this.signaturePad.clear()
  }

  drawComplete() {
    this.signatureBaseURL = this.signaturePad.toDataURL()
  }

  createFileFromBaseURL(url: string) {
    let date = new Date().toLocaleDateString()
    date = date.replace(/\//g, "-")
    const base64String = url.split(',')[1]
		const text = 'Shingle'
    const imageName = date + ' ' + text + '.png'
    const imageBlob = this.dataURItoBlob(base64String)
    return new File([imageBlob], imageName, { type: 'image/png' })
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  setCanvasWidth() {
    this.signaturePad.set('canvasWidth', this.signatureDiv.nativeElement.offsetWidth)
  }

  async getWarranty(){
    const data = await this.jobService.getWarrantyJob(this.jobID).toPromise()
    return data.body
  }

  async getBuilder(){
    const data = await this.jobService.getBuilderJob(this.jobID).toPromise()
    return data.body
  }

  ngOnInit() {
		let jobResponse
		const urlPath = location.href

    if (urlPath.includes('builder')) {
      this.jobType = 'builder'
      jobResponse = this.getBuilder()
		}
		else if (urlPath.includes('warranty')) {
      this.jobType = 'warranty'
      jobResponse = this.getWarranty()
    }

    jobResponse.then(res => {
      this.job = res

      // Get builder name
      this.builderService
        .getBuilder(this.job.BuilderID)
        .subscribe((res: any) => {
          this.builder = res.body
        })

      // Get community name
      this.communityService
        .getCommunity(this.job.CommunityID)
        .subscribe((res: any) => {
          this.community = res.body
        })

      // Get crew leader name
      if (this.jobType === 'builder') {
        this.userService
          .getUser(this.job.CrewLeaderID)
          .subscribe((res: any) => {
						this.crewLeader = res.body

						// get only users who are crew leaders and put in dropdown
						this.userService.getUsers( 3 ).subscribe(( result: any ) => {
							const users = result.body.data.map( user => {
								const text = user.FullName ? user.FullName : user.Email
								const id   = user.Id

								return { id, text }
							})
							this.crewLeaders = this.updateUsers( users )
						})
          })
			}

			this.checklistForm = this.formBuilder.group({
				type: 'Shingle',
				comments: '',
				CrewLeaderID: [ this.job.CrewLeaderID, Validators.required ],
				shingleInspectedDate: '',
				cricketsInstalled: ['', Validators.required],
				valleysBulled: ['', Validators.required],
				penetrationsInstalled: ['', Validators.required],
				penetrationsStepBulled: ['', Validators.required],
				ventFlashingSquish: ['', Validators.required],
				bootsFolded: ['', Validators.required],
				shinglesInstalledCorrectly: ['', Validators.required],
				shinglesCleaned: ['', Validators.required],
				otherEntries: this.formBuilder.array([this.createItem()]),
			});

			// Only Builder Jobs require a CrewLeaderID
			if ( this.jobType !== 'builder' ) {
				this.checklistForm.removeControl( 'CrewLeaderID' )
			}
    })
	}

	openModal(modal, windowClass) {
		// don't show modals if crew leader is logged in
		if(this.roleService.isCrewLeader) return

		this.modalService
			.open(modal, { ariaLabelledBy: "modal-basic-title", windowClass })
			.result.then(
				result => {

					for (let key in result) {
						const value = result[key]

						if( key === "CrewLeaderID") {
							this.updateCrewLeader( value )
						}

					}
				},
				reason => {
					// this.closeResult = this.getDismissReason(reason);
				}
			);
	}

	updateCrewLeader( id: string ) {
		this.checklistForm.controls[ 'CrewLeaderID' ].setValue( id )
		this.userService.getUser( id ).subscribe((result: any) => {
			let user = result.body
			this.crewLeader = user
		});
	}

  createItem(): FormGroup {
    return this.formBuilder.group({
      Input: '',
      Rating: '',
    });
  }

  addItem(): void {
    this.otherEntries = this.checklistForm.get('otherEntries') as FormArray;
    this.otherEntries.push(this.createItem());
  }

  removeItem(index): void {
    this.otherEntries.removeAt(index)
  }

  displayFilesOnUpload() {
    const input = document.querySelector('#fileUpload');
    // Create array from FileList
    this.builderFiles = Array.from((<HTMLInputElement>input).files)
  }

  get form() {
    return this.checklistForm.get('otherEntries')['controls']
  }

  handleFormSubmit() {
    this.submitted = true
		let data = this.checklistForm.value

		if (this.checklistForm.invalid) {
			this.alertService.error("Your form has errors. Make sure all inspection items have been checked")
			document.body.scrollIntoView({ behavior: "smooth" })
			return
		}

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
				// Look at all fields except comments, type & CrewLeaderID
				if (
					key !== "comments" &&
					key !== 'type' &&
					key !== 'CrewLeaderID'
				) {
					// if key corresponds to the two date fields
					if (key === 'dryInInspectionDate' || key === 'shingleInspectedDate' ) {
						if (data[key]) {
							// If a value exists, change the format for database to handle
							data[key] = `${data[key].year}/${data[key].month}/${data[key].day}`;
						}else{
							// set value to empty string
							data[key] = ''
						}
					} else if (key === "otherEntries") {
						data[key].forEach(( value, index ) => {
							if(!value.Input){
								data[key].splice(index, 1)
							}else{
								value.Rating = +value.Rating
							}
						})
					}
				}
      }
    }

    this.inspectionsService
      .createShingleChecklist(data)
      .subscribe((data: any) => {
				let signatureFile
				// save digital signature as a File
				if(this.signatureBaseURL){
					signatureFile = this.createFileFromBaseURL(this.signatureBaseURL)
				}else{
					this.alertService.error("You must sign the inspection to submit it");
					document.body.scrollIntoView({ behavior: "smooth" });
					return
				}
        this.filesService.uploadFile(signatureFile, data.ID, "Inspection").subscribe((res: any) => {
          // wait a second for the server to register the new files
          if (isArray(res)) {
            setTimeout(() => {
              data.SignatureFileID = res[0]
              this.inspectionsService.updateShingleChecklist(data.ID, data).subscribe((res: any) => { })
            }, 1000)
          }
        })

        if (this.builderFiles.length) {
          // Iterate through each file in the array
          this.builderFiles.forEach(file => {
            // send array of files to file upload service function
            this.filesService.uploadFile(file, data.ID, "Inspection").subscribe(
              res => {
                console.log(res)
              },
              err => console.log(err)
            )
          })
				}

				// Add created inspection to Job, then redirect to job
        let jobType

				switch ( this.jobType ) {
					case 'builder':
						jobType = 'BuilderJob'
						break;
					case 'warranty':
						jobType = 'Warranty'
						break;
					case 'reroof':
						jobType = 'Reroof'
						break;
					case 'repair':
						jobType = 'Repair'
						break;
				}

        this.inspectionsService
          .addChecklistToJob(data.ID, this.jobID, jobType, data)
          .subscribe((result: any) => {
            this.router.navigate([ `${this.jobType}-jobs/${this.jobID}` ]);
          })
      },
        error => {
          this.alertService.error(error);

          document.body.scrollIntoView({ behavior: "smooth" });
        }
      )
	}

	updateUsers(users = []): Observable<Array<Select2OptionData>> {
		return Observable.create((obs) => {
			obs.next(users);
			obs.complete();
		});
	}
}
