import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { JobsService } from '@/services/jobs/jobs.service';
import { BuildersService } from '@/services/builders/builders.service';
import { CommunitiesService } from '@/services/communities/communities.service';
import { ContactsService } from '@/services/contacts/contacts.service';
import { DataTableDirective } from 'angular-datatables';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'datatable-reroof-jobs',
  templateUrl: './reroof-jobs-datatable.component.html',
  styleUrls: ['./reroof-jobs-datatable.component.scss']
})
export class ReroofJobsDatatableComponent implements OnInit, OnDestroy {

	@ViewChild(DataTableDirective)
  	datatableElement: DataTableDirective;
  	
	@Input() title : string = '';
	@Input() showFooter : boolean = true;
	@Input() rowsPerPage : number = 10;
	@Input() viewRoute : string = '';
	@Input() viewText : string = 'View';
	@Input() status : string = 'all';
	@Input() showStatus : boolean = true;

	windowLoc = '';
	rows = [];
	dtOptions : DataTables.Settings = {};
	showTitle : Boolean = false
	activeStatusFilter: string = ''

	jobStatuses: string[] = [
		'Active',
		'Closed',
		'Estimated',
		'Removed'
	];
  constructor(
	  private jobsService: JobsService,
			private buildersService: BuildersService,
			private communitiesService: CommunitiesService,
			private contactsService: ContactsService,
		private config: NgbPopoverConfig
  ) { 
		config.placement = 'left';
   }


  	dataTablesInit(searchStr?: any) {
	    let lastPage = 0;
		let lastSearchText = "";
		let searchOptions : (DataTables.SearchSettings) = {search: searchStr};
		this.dtOptions = {
			pagingType: 'full_numbers',
			pageLength: this.rowsPerPage,
			lengthChange: false,
			displayStart: lastPage, // Last Selected Page
			info: this.showFooter,
			paging: this.showFooter,
			scrollX: true,
			search: searchOptions,
			columns: [
				{ "data": "CreatedDate" },
				{ "data": "ScheduledDate" },
				{
					"data": "HomeSize"
				},
				{
					"data": "Name"
				},
				{
					"data": "Phone"
				},
				{
					"data": "Email"
				},
				{
					"data": "Location"
				},
				{
					"data": "Status",
					"visible": this.showStatus,
					"orderable": false
				},
				{
					"data": "Inspections",
					render: function (data, type, row) {
						return '';
					}
				},
				{
					"data": "Detail",
					render: function (data, type, row) {
						return '';
					}
				}
			],
			columnDefs: [
				{
					targets: this.showStatus ? [8, 9] : [7, 8],
					orderable: false,
					searchable: false
				}
			],
			serverSide: true,
			processing: true,
			ajax: (data: object, callback, dataTablesParameters: any) => {
				lastPage = dataTablesParameters.start;  // Note :  dataTablesParameters.start = page count * table length
				lastSearchText = 'search' in dataTablesParameters && dataTablesParameters.search != 'undefined' ? dataTablesParameters.search.value : '';

				if ( this.activeStatusFilter !== '' ){
					data['columns'][6]['search'].value = this.activeStatusFilter.toLowerCase()
				}

				// Get the table data
				this.jobsService.getReroofJobsDatatable(data).subscribe((result: any) => {

					result.data.forEach((row) => {
						let status = row.Status;

						// Set Default status color
						row.StatusColor = 'dark';

						switch(status) {
							case 'Active':
								row.StatusColor = 'success';
							break;
							case 'Closed':
								row.StatusColor = 'danger';
							break;
							case 'Estimated':
								row.StatusColor = 'primary';
							break;
							case 'Inactive':
								row.StatusColor = 'warning';
							break;
						}

						row['SafetyChecklistAddLink'] = `/reroof-jobs/${row.ID}/safety-inspection-add`

						if (row.Inspections.length) {
							row.Inspections.sort(function (a, b) {
								const c: any = new Date(a.CreatedDate)
								const d: any = new Date(b.CreatedDate)
								return c - d
							});
						}
					});

					this.rows = result.data;
					callback({
						recordsTotal: result.recordsTotal,
						recordsFiltered: result.recordsFiltered,
						data: [] // this gets set to empty because this.rows above is rendered by angular with the relevant formatting
					});
					
					$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
					
					setTimeout(() => {
						$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
					}, 2500);
				});

			},
			order: [[0, "desc"]],
			initComplete: () => {
				let searchLabels = document.querySelectorAll('.dataTables_filter > label');

				searchLabels.forEach((label) => {
					label.setAttribute('aria-label', 'Search/Filter Table');
				});

				this.showTitle = true
			}
		};

		// console.log('dtOptions', this.dtOptions);
		setTimeout(() => {
			$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
		}, 2000);
	}

	ngOnInit(): void {
		this.windowLoc = window.location.pathname;
		let trySearch: any = window.sessionStorage.getItem(this.windowLoc + "reroofSearch");
		window.sessionStorage.clear();

		if (trySearch != null && trySearch != ''){
			this.dataTablesInit(trySearch);
		}
		else {
			this.dataTablesInit();
		}		
	}

	ngOnDestroy(): void {
		let dtinst: DataTables.Api;
		let search : any = "";

		this.datatableElement.dtInstance.then(x => {
			dtinst = x;
			search = dtinst.search();
			if (search != null || search != ''){
				window.sessionStorage.setItem(this.windowLoc + "reroofSearch", search);
			}
		});
	}

	updateStatusFilter( event: { value: string, data } ){
		const { value } = event

		this.activeStatusFilter = value

		this.datatableElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
			dtInstance.search('');
			dtInstance.columns().search('');
			dtInstance.draw();
		});
	}
}
