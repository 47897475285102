import { Component, OnInit, Input, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { AlertService } from '@/services/alert/alert.service';
import { CommentsService } from '@/services/comments/comments.service';
import { AuthService } from '@/services/auth/auth.service';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { Comment } from '@/models/comment';
import { TagifyComponent, TagData, TagifySettings, TagifyService } from 'ngx-tagify';
// import { User } from '@/models/user';
// import { text } from '@angular/core/src/render3';

@Component({
	selector: 'app-comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

	@Input() type: string = '';
	@Input() id: string = '';
	@Input() start: number;
	@Input() length: number;
	commentValue: string = '';
	// editCommentValue: string = '';

	@ViewChild('commentButton')
	commentButton: ElementRef;

	@ViewChildren("comments")
	commentsEl: QueryList<ElementRef>;

	// pagination variables
	entriesPerPage: number = 10;
	currentPage: number = 1;

	userRoleID = this.authService.currentUserValue['role'].id;

	newCommentSubmitted: Boolean = false
	newComment = new Comment();
	commentEdited = new Comment();
	editingComment: boolean = false;

	comments: Comment[];

	commentForm: FormGroup;
	editCommentForm: FormGroup;
	userList:TagData[];

	commentSettings: TagifySettings = {
		placeholder: 'Start typing...',
		// whitelist: this.userList,
		editTags: false,
		enforceWhitelist: true,
		mode: 'mix',    // <--  Enable mixed-content
		pattern: /@/,
		dropdown: {
			classname: "color-blue",
			enabled: 0,              // show the dropdown immediately on focus
			maxItems: 5,
			position: "text",         // place the dropdown near the typed text
			closeOnSelect: true,          // keep the dropdown open after selecting a suggestion
			highlightFirst: true
		},
		delimiters: ',| |\n|\r',
		callbacks: {
			change: (e) => { 
				// this.commentValue = e.detail.value;
				this.commentValue = this.parseTags(e.detail.tagify.DOM.input.innerHTML);
			 }
		}
	};

	constructor(
		private alertService: AlertService,
		private formBuilder: FormBuilder,
		private commentsService: CommentsService,
		private authService: AuthService,
		private tagifyService: TagifyService
	) { }

	ngOnInit() {

		this.commentForm = this.formBuilder.group({
			Comment: ''
		});

		this.editCommentForm = this.formBuilder.group({
			EditComment: ''
		});

		this.getAllCommentUsers();
		this.getAllComments();
	}

	parseTags = (inputHtml: string) => {
		const tmpParser = new DOMParser()

		var tagsRegexp = /<tag .*?<\/tag>/img;
		inputHtml = inputHtml.replace(tagsRegexp, function(match) {
			var matchElem = tmpParser.parseFromString(match, "text/html");
			var tagId = matchElem.querySelector('tag').getAttribute('id');
			var tagName = matchElem.querySelector('tag').getAttribute('value');
			var tagPrefix = matchElem.querySelector('tag').getAttribute('prefix');
		  return `[[{"id":"${tagId}","value":"${tagName}","prefix":"${tagPrefix}"}]]`;
		});

		return this.removeTags(inputHtml).replace(/&nbsp;/g, " ");
	}
	
	removeTags (str: string) {
		if ((str === null) || (str === ''))
			return '';
		else
			str = str.toString();
	 
		// Regular expression to identify HTML tags in
		// the input string. Replacing the identified
		// HTML tag with a null string.
		return str.replace(/(<([^>]+)>)/ig, '');
	}

	getAllCommentUsers() {
		this.commentsService.getCommentsUserList().subscribe((result) => {
			let tmpUserList = result.body['data'];
			let newUserList:TagData[] = new Array<TagData>();
			tmpUserList.forEach(user => {
				let newTag:TagData = {id:user.id, value:user.value};
				newUserList.push(newTag);
			});

			this.userList = newUserList;
			this.tagifyService.get('Comment').settings.whitelist = newUserList;
		});
	}

	getAllComments() {
		this.commentsService.getComments(this.id, this.type, this.start, this.length)
			.subscribe((result) => {
				let comments = result.body['data'];
				console.log('comments', comments);
				comments.forEach(comment => {
					console.log('comment1', comment);
					// Simplify tags for editing
					comment.EditText = this.commentsService.generateCleanEditText(comment.CommentText);
					comment.CleanCommentText = this.commentsService.generateCleanCommentText(comment.CommentText);

					console.log('comment2', comment);
					if (comment.CommentText.indexOf("\n") > 0) {
						comment.CommentText = comment.CommentText.replace(/\n/g, "<br/>");
					}
					console.log('comment3', comment);
				});

				// Sort comments in ascending order
				comments.sort((a, b) => {
					const date1 = new Date(a.CreatedDate)
					const date2 = new Date(b.CreatedDate)

					return date2.getTime() - date1.getTime()
				})

				console.log('comments final', comments);
				this.comments = comments;
		});
	}

	pageChanged(newPage: number) {
		this.currentPage = newPage
	}

	deleteComment(ID) {
		// Method must still be subscribed to to delete a comment
		this.commentsService.deleteComment(ID).subscribe((result) => {

			this.comments.forEach((comment, i) => {

				if (comment.ID === ID) {
					this.comments.splice(i, 1);
				}
			});
		});
	}

	clearCommentForm() {
		this.commentForm.reset();
		this.commentValue = '';
		this.tagifyService.get('Comment').removeAllTags();
	}

	get newCommentForm() {
		return this.commentForm.controls
	}

	onSubmit = () => {
		this.commentButton.nativeElement.toggleAttribute('readonly')
		this.newCommentSubmitted = true;
		
		if (this.commentValue.trim() == '') {
			console.error('form submission invalid');
			return;
		}

		// if (this.commentForm.invalid) {
		// 	console.error('form submission invalid');
		// 	return;
		// }

		this.newComment.ObjectID = this.id;
		this.newComment.ObjectType = this.type;
		this.newComment.CommentText = this.commentValue.trim();

		this.commentsService.createComment(this.newComment)
			.subscribe(
				(data: any) => {
					this.getAllComments();
					this.clearCommentForm();
					this.newCommentSubmitted = false
					this.alertService.success(`Comment Posted!`, true);
					this.commentButton.nativeElement.toggleAttribute('readonly')
				},
				error => {
					this.alertService.error(error);

					document.body.scrollIntoView({ behavior: "smooth" });
				}
			);
	}

	editComment(commentEdited) {
		this.commentEdited = commentEdited;
		commentEdited.editingComment = false;
		commentEdited.CommentText = this.editCommentForm.get('EditComment').value;

		this.commentsService.updateComment(commentEdited.ID, commentEdited)
			.subscribe((result) => {
				this.alertService.success(`Comment Updated!`, true);

				commentEdited.EditText = this.commentsService.generateCleanEditText(commentEdited.CommentText);
				commentEdited.CleanCommentText = this.commentsService.generateCleanCommentText(commentEdited.CommentText);

				//also replace the created date with the modified date
				commentEdited.CreatedDate = commentEdited.LastModifiedDate;

				this.comments.forEach(comment => {
					if (comment.ID === commentEdited.ID) {
						comment = commentEdited;
					}
				});
			},
			error => {
				this.alertService.error(error);
				document.body.scrollIntoView({ behavior: "smooth" });
			}

		);
	}

	toggleEditBoxes(event, commentEdited) {

		let targetID = event.currentTarget.id

		commentEdited['editingComment'] = !commentEdited['editingComment'];

		this.comments.forEach((comment, index) => {

			// let currentComment = this.commentsEl.toArray()[index].nativeElement;
			if (commentEdited.ID !== comment.ID) {
				comment['editingComment'] = false;
			}

			// Settimeout must be applied to allow time for the element to exist before focusing
			// if (currentComment.classList.contains(targetID)) {
			// 	setTimeout(() => currentComment.focus(), 0);
			// }
		});

	}

}
