import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
	endpoint = 'Employees';

	constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) { }

	private handleError(error: any, context: any = '') {
		console.error('Invalid Request', error, context);

		let message = `Failure: ${error}`;

		// if( context ) {
		// 	message = `${message} ${context}`;
		// }

		// return an observable with a user-facing error message
		return throwError(message);
	}

	public getEmployees( includeInactive: boolean = false ) {
		let url = `${this.config.apiUrl}/${this.endpoint}/all`;

		if ( includeInactive ) {
			url = url + '?includeInactive=true'
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getEmployee(id: string) {
		let url = `${this.config.apiUrl}/${this.endpoint}/get/${id}`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public createEmployee(submission: any) {
		let url  = `${this.config.apiUrl}/${this.endpoint}/create`,
			  data = {};

		for (let key in submission) {
			let value = submission[key]

			if (key === 'DOB' || key === 'HireDate' || (key === 'DrugTestCompletedDate' && value !== null)) {
				value = `${value.year}/${value.month}/${value.day}`;
			}

			data[key] = value;
		}

		return this.http.post(url, data)
			.pipe(
				catchError(error => this.handleError(error, 'Check your submisision and try again.') )
			);
	}

	public updateEmployee(id: string, submission: any) {
		let url = `${this.config.apiUrl}/${this.endpoint}/update/${id}`,
			  data = {}

		for (let key in submission) {
			let value = submission[key]

			if (key === 'DOB' && typeof value !== 'string' || key === 'HireDate' && typeof value !== 'string' || key === 'DrugTestCompletedDate' && typeof value !== 'string') {
				if(value !== null)
  				value = `${value.year}/${value.month}/${value.day}`;
			}

			data[key] = value;
		}

		return this.http.put(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public addIncident(employeeID: string, incidentID: string) {
		let url = `${this.config.apiUrl}/${this.endpoint}/addincident?employeeID=${employeeID}&incidentID=${incidentID}`

		return this.http.put(url, {}, {})
			.pipe(
				catchError(this.handleError)
			);
	}

	public deleteIncident(employeeID: string, incidentID: string) {
		let url = `${this.config.apiUrl}/${this.endpoint}/deleteincident?employeeID=${employeeID}&incidentID=${incidentID}`

		return this.http.put(url, {}, {})
			.pipe(
				catchError(this.handleError)
			);
	}

	public updateEmployeeStatus( id: string, data, active: boolean ) {
		const url = `${ this.config.apiUrl }/${ this.endpoint }/update/${ id }`;

		data.active = active

		return this.http.put( url, data )
			.pipe(
				catchError(this.handleError)
			);
	}
}
