import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NgSelect2Module } from 'ng-select2';
import { DataTablesModule } from 'angular-datatables';

import { AuthGuard } from '@/guards/auth/auth.guard';
import { Role } from '@/models/role';

import { AlertsDatatableComponent } from '@/components/datatable/alerts-datatable/alerts-datatable.component';
import { AlertsComponent } from './alerts.component';

export const routes: Routes = [
	{
		path: 'view',
		component: AlertsComponent,
		canActivate: [AuthGuard],
		data: { roles: [Role.Admin, Role.Supervisor, Role.CrewLeader] }
	},
	{
		path: '',
		redirectTo: '/alerts/view',
		pathMatch: 'full'
	},
	{
		path: '**',
		redirectTo: '/alerts/view',
		pathMatch: 'full'
	}
];

@NgModule({
	declarations: [
		AlertsComponent,
		AlertsDatatableComponent,
	],
	imports: [
		CommonModule,
		NgSelect2Module,
		DataTablesModule,
		RouterModule.forChild(routes),
	],
	exports: [
		AlertsDatatableComponent
	]
})
export class AlertsModule { }
