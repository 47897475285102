import { Role } from '@/models/role';

export const MainNavItems = [
	{
		icon: "fas fa-columns",
		route: "/dashboard",
		text: "Dashboard",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor, Role.CrewLeader],
		children: []
	},
	{
		icon: "fas fa-hammer",
		route: "",
		text: "Builder Jobs",
		visible: "",
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor, Role.CrewLeader],
		children: [
			{
				route: "/builder-jobs/add",
				text: "Add Builder Job",
				visible: true,
				roles: [Role.Admin]
			},
			{
				route: "/builder-jobs/view",
				text: "View Builder Jobs",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			},
			{
				route: "/builder-jobs/calendar",
				text: "View Calendar",
				visible: true,
			    roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			},
			{
				route: "/builder-jobs/mycalendar",
				text: "View My Calendar",
				visible: true,
			    roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			}
		]
	},
	{
		icon: "fas fa-home",
		route: "#",
		text: "Re-Roofs",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor, Role.CrewLeader],
		children: [
			{
				route: "/reroof-jobs/add",
				text: "Add Re-Roof Estimate",
				visible: true,
				roles: [Role.Admin]
			},
			{
				route: "/reroof-jobs/view",
				text: "View Re-Roof Jobs",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			},
			{
				route: "/reroof-jobs/calendar",
				text: "View Calendar",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			},
			{
				route: "/reroof-jobs/mycalendar",
				text: "View My Calendar",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			}
		]
	},
	{
		icon: "fas fa-cogs",
		route: "#",
		text: "Repairs",
		visible: "",
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor, Role.CrewLeader],
		children: [
			{
				route: "/repair-jobs/add",
				text: "Add Repair Job",
				visible: true,
				roles: [Role.Admin]
			},
			{
				route: "/repair-jobs/view",
				text: "View Repair Jobs",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			},
			{
				route: "/repair-jobs/calendar",
				text: "View Calendar",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			},
			{
				route: "/repair-jobs/mycalendar",
				text: "View My Calendar",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			}
		]
	},
	{
		icon: "fas fa-file-exclamation",
		route: "#",
		text: "Warranty Services",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor, Role.CrewLeader],
		children: [
			{
				route: "/warranty-jobs/add",
				text: "Add Warranty Job",
				visible: true,
				roles: [Role.Admin]
			},
			{
				route: "/warranty-jobs/view",
				text: "View Warranty Jobs",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			},
			{
				route: "/warranty-jobs/calendar",
				text: "View Calendar",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			},
			{
				route: "/warranty-jobs/mycalendar",
				text: "View My Calendar",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			}
		]
	},
	{
		icon: "fas fa-wrench",
		route: "#",
		text: "Builders",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor],
		children: [
			{
				route: "/builders/add",
				text: "Add Builder",
				visible: true,
				roles: [Role.Admin]
			},
			{
				route: "/builders/view",
				text: "View Builders",
				visible: true,
				roles: [Role.Admin, Role.Supervisor]
			}
		]
	},
	{
		icon: "fas fa-home",
		route: "#",
		text: "Models",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor],
		children: [
			{
				route: "/models/add",
				text: "Add Model",
				visible: true,
				roles: [Role.Admin]
			},
			{
				route: "/models/view",
				text: "View Models",
				visible: true,
				roles: [Role.Admin, Role.Supervisor]
			}
		]
	},
	{
		icon: "fas fa-building",
		route: "#",
		text: "Communities",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor],
		children: [
			{
				route: "/communities/add",
				text: "Add Community",
				visible: true,
				roles: [Role.Admin]
			},
			{
				route: "/communities/view",
				text: "View Communities",
				visible: true,
				roles: [Role.Admin, Role.Supervisor]
			}
		]
	},
	{
		icon: "fas fa-users",
		route: "#",
		text: "Contacts",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor],
		children: [
			{
				route: "/contacts/add",
				text: "Add Contact",
				visible: true,
				roles: [Role.Admin]
			},
			{
				route: "/contacts/view",
				text: "View Contacts",
				visible: true,
				roles: [Role.Admin, Role.Supervisor]
			},
		]
	},
	{
		icon: "fas fa-users",
		route: "#",
		text: "Employees",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor],
		children: [
			{
				route: "/employees/add",
				text: "Add Employee",
				visible: true,
				roles: [Role.Admin, Role.Supervisor]
			},
			{
				route: "/employees/view",
				text: "View Employees",
				visible: true,
				roles: [Role.Admin, Role.Supervisor]
			},
		]
	},
	{
		icon: "fas fa-exclamation-triangle",
		route: "#",
		text: "Incidents",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor],
		children: [
			{
				route: "/incidents/add",
				text: "Add Incident",
				visible: true,
				roles: [Role.Admin, Role.Supervisor]
			},
			{
				route: "/incidents/view",
				text: "View Incidents",
				visible: true,
				roles: [Role.Admin, Role.Supervisor]
			},
		]
	},
	{
		icon: "fas fa-users",
		route: "#",
		text: "Users",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin],
		children: [
			{
				route: "/users/add",
				text: "Add User",
				visible: true,
				roles: [Role.Admin]
			},
			{
				route: "/users/view",
				text: "View Users",
				visible: true,
				roles: [Role.Admin]
			}
		]
	},
	{
		icon: "fas fa-clock",
		route: "#",
		text: "Time",
		visible: true,
		isCollapsed: true,
		roles: [Role.Admin, Role.Supervisor, Role.CrewLeader],
		children: [
			{
				route: "/time-entries/add",
				text: "Add Time Entry",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			},
			{
				route: "/time-entries/view",
				text: "View Time Entries",
				visible: true,
				roles: [Role.Admin, Role.Supervisor, Role.CrewLeader]
			}
		]
	},
	{
		icon: "fas fa-exclamation-triangle",
		route: "#",
		text: "Incidents",
		visible: true,
		isCollapsed: true,
		children: [
			{
				route: "/incidents/add",
				text: "Add Incident",
				visible: true
			},
			{
				route: "/incidents/view",
				text: "View Incidents",
				visible: true
			}
		]
	}
];
