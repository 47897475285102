import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
	endpoint = 'contacts';

	constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) { }

  private handleError(error: any, context: any = '') {
		console.error('Invalid Request')

		let message = `Failure: ${error}`;

		// if( context ) {
		// 	message = `${message} ${context}`;
		// }

		// return an observable with a user-facing error message
		return throwError(message);
	}

	public getContacts() {
		let url = `${this.config.apiUrl}/${this.endpoint}/all`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getContact(id: string) {
		let url = `${this.config.apiUrl}/${this.endpoint}/get/${id}`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public createContact(submission: any) {
		let url  = `${this.config.apiUrl}/${this.endpoint}/create`,
		  	data = {};

		for (let key in submission) {
			var control = submission[key],
				value = control.value;

			data[key] = value;
		}

		return this.http.post(url, data)
			.pipe(
				catchError(error => this.handleError(error, 'Check your submisision and try again.') )
			);
	}

	public updateContact(id, data: any) {
		let url = `${this.config.apiUrl}/${this.endpoint}/update?id=${id}`;

		return this.http.put(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}
}
