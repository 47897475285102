import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
	providedIn: 'root'
})
export class CommentsService {

	tagsRegexp = /\[\[({.*?})\]\]/sg;
	editTagsRegexp = /\[@(.*?)\]/sg;

	constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) { }

	private handleError(error: HttpErrorResponse, obs) {

		console.error('raw error:', error, 'headers:', obs, obs.status, obs.statusText, obs.message);

		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		}
		else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}

		// return an observable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}

	public getComments(objectID: string, objectType: string, start = 0, length = 99) {
		let endpoint = 'Comments',
			url = `${this.config.apiUrl}/${endpoint}/list?objectID=${objectID}&objectType=${objectType}`;

		if (start !== 0) {
			url += `&start=${start}`;
		}

		if (length !== 0) {
			url += `&length=${length}`;
		}

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			)
	}

	public getCommentsUserList() {
		let endpoint = 'Comments',
			url = `${this.config.apiUrl}/${endpoint}/userlist`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			)
	}

	public getComment(id: string) {
		let endpoint = 'Comments',
			url = `${this.config.apiUrl}/${endpoint}/get/${id}`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public createComment(submission: any) {
		let endpoint = 'Comments',
			url = `${this.config.apiUrl}/${endpoint}/create`;

		console.log(submission);

		return this.http.post(url, submission)
			.pipe(
				catchError(this.handleError)
			);
	}

	public updateComment(id: string, data: any) {
		let endpoint = 'Comments',
			url = `${this.config.apiUrl}/${endpoint}/update/${id}`;

		// console.log(data);
		// console.log(id);
		return this.http.put(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public deleteComment(id: string) {
		let endpoint = 'Comments',
			url = `${this.config.apiUrl}/${endpoint}/delete/${id}`;
		// console.log(url);


		return this.http.delete(url)
			.pipe(
				catchError(this.handleError)
			);
	}

	public generateCleanEditText(input: any) {
		return input.replaceAll(this.tagsRegexp, function (match, group) {
			try {
				var obj = JSON.parse(group);
				if ('value' in obj) {
					return "[@" + obj.value + "]";
				}
			} catch (SyntaxError) {
			}

			return match;
		});
	}

	public generateCleanCommentText(input: any) {
		return input.replaceAll(this.tagsRegexp, function(match, group) {
			try {
				var obj = JSON.parse(group);
				if('value' in obj && 'prefix' in obj) {
					return "&nbsp;<strong>" + obj.prefix + obj.value + "</strong>&nbsp;";
				}
			} catch(SyntaxError) {
			}

			return match;
		}).replaceAll(this.editTagsRegexp, function(match, group) {
			return "&nbsp;<strong>@" + group + "</strong>&nbsp;";
		});
	}
}
