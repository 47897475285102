import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';
import { User } from '@/models/user';

@Injectable({ providedIn: 'root' })

export class AuthService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
			private http: HttpClient,
			@Inject(APP_CONFIG) private config: AppConfig
		) {
			this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
			this.currentUser        = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
			return this.currentUserSubject.value;
    }

		handleError<T> (operation = 'operation', result?: T) {
			return (error: any): Observable<T> => {
				console.error(error);

				// Return empty result to ensure app keeps running
				// return of(result as T);
				return throwError(error);
			}
		}

    login(UserName: string, Password: string) {

        return this.http.post<any>(`${this.config.apiUrl}/account/login`, { UserName, Password })
					.pipe(
						catchError(this.handleError('login', [])),
						map(user => {
								// login successful if there's a jwt token in the response
								console.log(user);
								if( user && user.token ) {
										// store user details and jwt token in local storage to keep user logged in between page refreshes
										localStorage.setItem('currentUser', JSON.stringify(user));
										this.currentUserSubject.next(user);
								}

								return user;
						})
					);
    }

    logout() {
        // remove user from local storage to log user out
				localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
