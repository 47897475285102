import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({ providedIn: 'root' })
export class UserService {
	endpoint = 'users'

	constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) { }

	private handleError(error: any, context: any = '') {
		console.error('Invalid Request')

		let message = `Failure: ${error}`;

		// if( context ) {
		// 	message = `${message} ${context}`;
		// }

		// return an observable with a user-facing error message
		return throwError(message);
	}

	public getUsers(roleID = 1) {
		const url = `${this.config.apiUrl}/${this.endpoint}/list?roleID=${roleID}`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public getAllUsers() {
		const url = `${this.config.apiUrl}/${this.endpoint}/all`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	// Get single user by id
	public getUser(userID) {
		const url = `${this.config.apiUrl}/${this.endpoint}/get/${userID}`;

		return this.http.get(url, { observe: 'response' })
			.pipe(
				catchError(this.handleError)
			);
	}

	public createUser(data: any) {
		let url = `${this.config.apiUrl}/${this.endpoint}/create`;

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public updateUser(id, data: any) {
		let url = `${this.config.apiUrl}/${this.endpoint}/update/${id}`;

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
	}

	public deactivateUser( id: string ) {
		const url = `${ this.config.apiUrl }/${ this.endpoint }/delete/${ id }`;

		return this.http.delete( url )
			.pipe(
				catchError(this.handleError)
			);
	}

	public resetPassword(email: string) {
		const endpoint = 'Account',
			    url = `${this.config.apiUrl}/${endpoint}/forgotpassword?email=${email}`;

		return this.http.post(url, email)
			.pipe(
				catchError(error => this.handleError(error, 'Check your submission and try again.'))
			);
	}


	public newPassword(password: string, token: string) {
		const endpoint = 'Account',
			    url = `${this.config.apiUrl}/${endpoint}/changepassword`;

			const data = {
				password: password,
				resetToken: token
			}

		return this.http.post(url, data)
			.pipe(
				catchError(error => this.handleError(error, 'Check your submission and try again.'))
			);
	}
}
