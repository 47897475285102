import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import { AlertService } from '@/services/alert/alert.service';

@Component({
	selector: 'alert',
	templateUrl: 'alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
		message: any;
		type: any;
		showAlert  = false;
		alertClass:string = '';

    constructor(
			private alertService: AlertService,
			private sanitizer: DomSanitizer
		) { }

    ngOnInit() {
			this.subscription = this.alertService.getAlert()
				.subscribe(data => {

					if( data ) {
						let type       = data.type,
								message    = data.text,
								alertClass = data.class;

						// Set type using Bootstrap conventions
						if( type === 'error' ) {
							type = 'danger';
						}

						if( type && message ) {
							this.showAlert  = true;
							this.type       = type;
							this.alertClass = alertClass;
							this.message    = this.sanitizer.bypassSecurityTrustHtml(message);
						}
					}
				});
    }

    ngOnDestroy() {
			this.subscription.unsubscribe();
			console.log('alert destroyed');
		}

		close() {
			this.alertService.clear();
			this.message = {};
			this.showAlert = false;
		}
}
