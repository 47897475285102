import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
  providedIn: "root"
})
export class InspectionsService {
  endpoint = "inspection";

  constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  public getSafetyChecklist(id:string = "") {
    let url = `${this.config.apiUrl}/${this.endpoint}/getsafety/${id}`;

    return this.http
      .get(url, { observe: "response" })
      .pipe(catchError(this.handleError));
  }

  public getAllSafetyChecklists() {
    let url = `${this.config.apiUrl}/${this.endpoint}/allsafety`;

    return this.http
      .get(url, { observe: "response" })
      .pipe(catchError(this.handleError));
  }

  public createSafetyChecklist(data: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/createsafety`

    return this.http.post(url, data).pipe(catchError(this.handleError));
  }

  public updateSafetyChecklist(id:string, data: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/updatesafety/${id}`;

    return this.http.put(url, data).pipe(catchError(this.handleError));
  }

  public getShingleChecklist(id:string = "") {
    let url = `${this.config.apiUrl}/${this.endpoint}/getshingle/${id}`;

    return this.http
      .get(url, { observe: "response" })
      .pipe(catchError(this.handleError));
  }

	public getAllShingleChecklists() {
    let url = `${this.config.apiUrl}/${this.endpoint}/allshingle`;

    return this.http
      .get(url, { observe: "response" })
      .pipe(catchError(this.handleError));
  }

	public createShingleChecklist(data: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/createshingle`

    return this.http.post(url, data).pipe(catchError(this.handleError));
  }

	public updateShingleChecklist(id: string, data: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/updateshingle/${id}`;

    return this.http.put(url, data).pipe(catchError(this.handleError));
  }

  public getDryinChecklist(id:string = "") {
    let url = `${this.config.apiUrl}/${this.endpoint}/getdryin/${id}`;

    return this.http
      .get(url, { observe: "response" })
      .pipe(catchError(this.handleError));
  }

	public getAllDryinChecklists() {
    let url = `${this.config.apiUrl}/${this.endpoint}/alldryin`;

    return this.http
      .get(url, { observe: "response" })
      .pipe(catchError(this.handleError));
  }

	public createDryinChecklist(data: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/createdryin`

    return this.http.post(url, data).pipe(catchError(this.handleError));
  }

	public updateDryinChecklist(id: string, data: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/updatedryin/${id}`;

    return this.http.put(url, data).pipe(catchError(this.handleError));
  }

  public addChecklistToJob(inspectionID, jobID, jobType, data: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/addjob?inspectionID=${inspectionID}&jobID=${jobID}&jobType=${jobType}`;

    if ("Status" in data) {
      if (data["Status"] == null) {
        data["Status"] = "Active";
      }
    }

    return this.http.put(url, data).pipe(catchError(this.handleError));
  }

  public deleteChecklist(id: string) {
    const url = `${this.config.apiUrl}/${this.endpoint}/delete/${id}`;

    return this.http.delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }
}
