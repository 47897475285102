import { Injectable, Inject } from "@angular/core";
import {
	HttpClient,
	HttpErrorResponse
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
	providedIn: "root"
})
export class BuildersService {
	endpoint = "builders";

	constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) {}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		}
		else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}

		// return an observable with a user-facing error message
		return throwError("Something bad happened; please try again later.");
	}

	public getBuilder(id = "") {
		let url = `${this.config.apiUrl}/${this.endpoint}/get/${id}`;

		return this.http
			.get(url, { observe: "response" })
			.pipe(catchError(this.handleError));
	}

	public getBuilders() {
		let url = `${this.config.apiUrl}/${this.endpoint}/all`;

		return this.http
			.get(url, { observe: "response" })
			.pipe(catchError(this.handleError));
	}

	public createBuilder(submission: any) {
		let url = `${this.config.apiUrl}/${this.endpoint}/create`,
			data = {};

		for (let key in submission) {
			var control = submission[key],
				value = control.value;

			data[key] = value;
		}

		return this.http.post(url, data).pipe(catchError(this.handleError));
	}

	public updateBuilder(id, data: any) {
		let	url = `${this.config.apiUrl}/${this.endpoint}/update/${id}`;

		if ("Status" in data) {
			if (data["Status"] == null) {
				data["Status"] = "Active";
			}
		}
		else {
			data["Status"] = "Active";
		}

		return this.http.put(url, data).pipe(catchError(this.handleError));
	}
}
