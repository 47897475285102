import { Injectable } from '@angular/core';

import { AuthService } from '@/services/auth/auth.service';

import { Role } from '@/models/role';
import { User } from '@/models/user';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  currentUser: User

  constructor(private authService: AuthService)
  {
    this.authService.currentUser.subscribe(user => this.currentUser = user);
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role.name === Role.Admin;
  }

  get isSupervisor() {
    return this.currentUser && this.currentUser.role.name === Role.Supervisor;
  }

  get isCrewLeader() {
    return this.currentUser && this.currentUser.role.name === Role.CrewLeader;
  }
}
