import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit, OnChanges {

	menuOpen: boolean = false;
	@Input('toggleIcon') toggleIcon: boolean = false;
	@Output() menuToggle = new EventEmitter();

  constructor() { }

  ngOnInit() {
	}

	ngOnChanges() {
		this.menuOpen = this.toggleIcon;
	}

	toggleSidebar(event) {
		event.stopPropagation();
		this.menuToggle.emit(this.menuOpen = !this.menuOpen);
		return false;
	}

}
