import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { JobsService } from '@/services/jobs/jobs.service';
import { BuildersService } from '@/services/builders/builders.service';
import { CommunitiesService } from '@/services/communities/communities.service';
import { ContactsService } from '@/services/contacts/contacts.service';
import { DataTableDirective } from 'angular-datatables';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'datatable-warranty-jobs',
  templateUrl: './warranty-jobs-datatable.component.html',
  styleUrls: ['./warranty-jobs-datatable.component.scss']
})
export class WarrantyJobsDatatableComponent implements OnInit, OnDestroy {

	@ViewChild(DataTableDirective)
  	datatableElement: DataTableDirective;

	@Input() title: string = '';
	@Input() showFooter: boolean = true;
	@Input() rowsPerPage: number = 10;
	@Input() viewRoute: string = '';
	@Input() viewText: string = 'View';
	@Input() status : string = 'all';
	@Input() showStatus : boolean = true;

	windowLoc = '';
	rows = [];
	dtOptions : DataTables.Settings = {};
	showTitle : Boolean = false
	
	jobStatuses: string[] = [
		'Active',
		'Completed',
		'Estimated',
		'Inactive',
		'Removed'
	];
	constructor(
		private jobsService: JobsService,
			private buildersService: BuildersService,
			private communitiesService: CommunitiesService,
			private contactsService: ContactsService,
		private config: NgbPopoverConfig
	) {
		config.placement = 'left';
	}

  	dataTablesInit(searchStr?: any) {
	    let lastPage = 0;
		let lastSearchText = "";
		let searchOptions : (DataTables.SearchSettings) = {search: searchStr}
		this.dtOptions = {
			pagingType: 'full_numbers',
			pageLength: this.rowsPerPage,
			lengthChange: false,
			displayStart: lastPage, // Last Selected Page
			info: this.showFooter,
			paging: this.showFooter, 
			scrollX: true,
			search: searchOptions,
			columns: [
				{ "data": "CreatedDate" },
				{ "data": "ScheduledDate" },
				{
					"data": "Builder",
					render: function (data, type, row) {
							return 'CompanyName' in data ? data.CompanyName : '';
					}
				},
				{
					"data": "Community",
					render: function (data, type, row) {
							return 'Name' in data ? data.Name : '';
					}
				},
				{
					"data": "Address",
					render: function (data, type, row) {
							return data + ' ' + (row.Address2 != null ? row.Address2 : '') + '<br />' + row.City + ', ' + row.State + ' ' + row.ZipCode;
					}
				},
				{
					"data": "CommunityManagers",
					render: function (data, type, row) {
							return data.length > 0 ? data.join('<br />') : '';
					},
					"orderable": false
				},
				{
					"data": "Status",
					"visible": this.showStatus,
					"orderable": false
				},
				{
					"data": "Inspections",
					render: function (data, type, row) {
						return '';
					}
				},
				{
					"data": "Detail",
					render: function (data, type, row) {
						return '';
					}
				}
			],
			columnDefs: [
				{
					targets: this.showStatus ? [7, 8] : [6, 7],
					orderable: false,
					searchable: false
				}
			],
			serverSide: true,
			processing: true,
			ajax: (data: object, callback, dataTablesParameters: any) => {
				lastPage = dataTablesParameters.start;  // Note :  dataTablesParameters.start = page count * table length
				lastSearchText = 'search' in dataTablesParameters && dataTablesParameters.search != 'undefined' ? dataTablesParameters.search.value : '';

				this.jobsService.getWarrantyJobsDatatable(data).subscribe((result: any) => {
					// Get all Rows
					result.data.forEach(row => {
						let communityManagers: Array<any> = []

						this.buildersService.getBuilder(row.BuilderID).subscribe((result: any) => {
							row.Builder = result.body;
						});

						this.communitiesService.getCommunity(row.CommunityID).subscribe((result: any) => {
							row.Community = result.body;
							row.Community.ManagerIDs.forEach(( id: string ) => {
								this.contactsService.getContact( id ).subscribe(( result: any ) => {
									communityManagers.push({
										name: result.body.Name,
										id: result.body.ID
									});
								});
							})
						});

						row['Lots'] = [row.Lot1, row.Lot2].filter(Boolean).join("/");
						let status = row.Status;

						// Set Default status color
						row.StatusColor = 'dark';

						switch(status) {
							case 'Active':
								row.StatusColor = 'success';
							break;
							case 'Completed':
								row.StatusColor = 'danger';
							break;
							case 'Estimated':
								row.StatusColor = 'primary';
							break;
							case 'Inactive':
								row.StatusColor = 'warning';
							break;
						}

						row['DryinChecklistAddLink']  = `/warranty-jobs/${row.ID}/dryin-inspection-add`
						row['ShingleChecklistAddLink']  = `/warranty-jobs/${row.ID}/shingle-inspection-add`
						row['SafetyChecklistAddLink']   = `/warranty-jobs/${row.ID}/safety-inspection-add`

						if (row.Inspections.length) {
							row.Inspections.sort(function (a, b) {
								const c: any = new Date(a.CreatedDate)
								const d: any = new Date(b.CreatedDate)
								return c - d
							});
						}

						row.CommunityManagers = communityManagers;
					});

					// console.log(this.rows);
					// Store rows in class property
					this.rows = result.data;
					callback({
						recordsTotal: result.recordsTotal,
						recordsFiltered: result.recordsFiltered,
						data: [] // this gets set to empty because this.rows above is rendered by angular with the relevant formatting
					});
					
					$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
					
					setTimeout(() => {
						$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
					}, 2500);
				});
			},
			order: [[0, "desc"]],
			initComplete: () => {
				let searchLabels = document.querySelectorAll('.dataTables_filter > label');

				searchLabels.forEach((label) => {
					label.setAttribute('aria-label', 'Search/Filter Table');
				});

				this.showTitle = true
			}
		};

		// console.log('dtOptions', this.dtOptions);
		setTimeout(() => {
			$($.fn.dataTable.tables(true)).DataTable().columns.adjust();
		}, 2000);
	}

	ngOnInit(): void {
		this.windowLoc = window.location.pathname;
		let trySearch: any = window.sessionStorage.getItem(this.windowLoc + "warrantySearch");
		window.sessionStorage.clear();

		if (trySearch != null && trySearch != ''){
			this.dataTablesInit(trySearch);
		}
		else {
			this.dataTablesInit();
		}		
	}

	ngOnDestroy(): void {
		let dtinst: DataTables.Api;
		let search : any = "";

		this.datatableElement.dtInstance.then(x => {
			dtinst = x;
			search = dtinst.search();
			if (search != null || search != ''){
				window.sessionStorage.setItem(this.windowLoc + "warrantySearch", search);
			}
		});
	}
}
