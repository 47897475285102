import { Injectable, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root'
})

export class UtilitiesService {

	constructor(
		@Inject(DOCUMENT) private document: Document
	) { }

	formatAddressString(address) {
		if (!address) return false;

		let start = [],
			end = [],
			output = [];

		// Setup address parts
		if (address.address) start.push(address.address);

		if (address.address2) start.push(address.address2);

		if (address.city) start.push(address.city);

		if (address.state) end.push(address.state);

		if (address.zipCode) end.push(address.zipCode);

		// Remove empty values
		start = start.filter((value) => {
			value = value.trim();

			return value ? true : false;
		});

		// Remove empty values
		end = end.filter((value) => {
			value = value.trim();

			return value ? true : false;
		});

		// Setup output string
		if (start.length) output.push(start.join(' ').trim());
		if (end.length) output.push(end.join(' ').trim());

		return output.join(', ');
	}

	/**
	 * Append the JS tag to the Document Body.
	 * @param renderer The Angular Renderer
	 * @param src The path to the script
	 * @returns the script element
	 */
	public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
		const script = renderer.createElement('script');
		script.type = 'text/javascript';
		script.src = src;
		renderer.appendChild(this.document.body, script);
		return script;
	}

	public loadTransformersJsModule(renderer: Renderer2): HTMLScriptElement {
		const script = renderer.createElement('script');
		script.type = 'module';
		script.appendChild(document.createTextNode('import { pipeline } from \'https://cdn.jsdelivr.net/npm/@xenova/transformers@2.17.2\';window.transformersPipeline = pipeline;'));
		renderer.appendChild(this.document.body, script);
		return script;
	}
}
