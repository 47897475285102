import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class CommunitiesService {

  endpoint = 'communities'

  constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
  ) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error}`);
    }

    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public getCommunity(id = '') {
    let url = `${this.config.apiUrl}/${this.endpoint}/get?id=${id}`;

    return this.http.get(url, { observe: 'response' })
      .pipe(
        catchError(this.handleError)
      );
  }

  public getCommunities() {
    let url = `${this.config.apiUrl}/${this.endpoint}/all`;

    return this.http.get(url, { observe: 'response' })
      .pipe(
        catchError(this.handleError)
      );
	}

  public createCommunity(data) {
    let url = `${this.config.apiUrl}/${this.endpoint}/create`;

	return this.http.post(url, data)
	  .pipe(
	    catchError(this.handleError)
	  );
  }

	public updateCommunity(id, data: any) {
    let url = `${this.config.apiUrl}/${this.endpoint}/update/${id}`;

	return this.http.put(url, data)
	  .pipe(
	    catchError(this.handleError)
	  );
	}
}
