import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { JobsService } from '@/services/jobs/jobs.service';
import { BuildersService } from '@/services/builders/builders.service';
import { CommunitiesService } from '@/services/communities/communities.service';
import { UserService } from '@/services/user/user.service';
import { EmployeesService } from '@/services/employees/employees.service';
import { InspectionsService } from '@/services/inspections/inspections.service';
import { FilesService } from '@/services/files/files.service';
import { AlertService } from '@/services/alert/alert.service';
import { RoleService } from '@/services/role/role.service';

import { BuilderJob } from '@/models/builder-job';
import { Community } from '@/models/community';
import { User } from '@/models/user';
import { Builder } from '@/models/builder';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { isArray } from 'util';

import { Select2OptionData } from 'ng-select2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-safety-inspection-add',
  templateUrl: './safety-inspection-add.component.html',
  styleUrls: ['./safety-inspection-add.component.scss']
})
export class SafetyInspectionAddComponent implements OnInit {
  @ViewChild('inspectionTable')
  inspectionTable: ElementRef;

  @ViewChild(SignaturePad)
  signaturePad: SignaturePad;

  @ViewChild('signatureDiv')
  signatureDiv: ElementRef;

  jobID         : string
  jobType       : string
  job           : BuilderJob
  community     : Community
  builder       : Builder
  crewLeader    : User
  crewLeaders   : Observable<Array<Select2OptionData>>
  checklistForm : FormGroup
  otherEntries  : FormArray
  uploadError   : string;

  signatureBaseURL : string;

  builderFiles   = [];
  uploadResponse = { status: '', message: '', filePath: '' }
  submitted      = false
  selectOptions  = [
    { name: "N/A", value: 0 },
    { name: "Non-compliant", value: 1 },
    { name: "Compliant", value: 2 },
  ]
  signaturePadOptions: Object = {
    'minWidth': 3,
    'canvasWidth': 500,
    'canvasHeight': 200,
	}
	select2Options  = {
		theme: "bootstrap4",
		width: "100%"
	}

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private jobService: JobsService,
    private builderService: BuildersService,
    private communityService: CommunitiesService,
    private userService: UserService,
    private employeeService: EmployeesService,
		public roleService: RoleService,
		private modalService: NgbModal,
    private inspectionsService: InspectionsService,
    private filesService: FilesService,
    private alertService: AlertService,
  ) {
    this.jobID = this.route.snapshot.paramMap.get("id");
		this.employeeService = employeeService;
  }

  ngAfterViewInit() {
		if( this.signaturePad ){
			this.signaturePad.set('minWidth', 3)
			this.setCanvasWidth()
			this.signaturePad.clear()
		}
  }

  clearSignature(){
    this.signaturePad.clear()
  }

  drawComplete() {
    this.signatureBaseURL = this.signaturePad.toDataURL()
  }

  createFileFromBaseURL(url: string){
    let date = new Date().toLocaleDateString()
    date = date.replace(/\//g, "-")
    const base64String = url.split(',')[1]
    const text = 'Safety'
    const imageName = date + ' ' + text + '.png'
    const imageBlob = this.dataURItoBlob(base64String)
    return new File([imageBlob], imageName, { type: 'image/png' })
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  setCanvasWidth() {
    this.signaturePad.set('canvasWidth', this.signatureDiv.nativeElement.offsetWidth )
  }

  async getWarranty(){
    const data = await this.jobService.getWarrantyJob(this.jobID).toPromise()
    return data.body
  }

  async getBuilder(){
    const data = await this.jobService.getBuilderJob(this.jobID).toPromise()
    return data.body
  }

  async getRepair(){
    const data = await this.jobService.getRepairJob(this.jobID).toPromise()
    return data.body
  }

  async getReroof(){
    const data = await this.jobService.getReroofJob(this.jobID).toPromise()
    return data.body
  }

  ngOnInit() {
		let jobResponse
		const urlPath = location.href

    if (urlPath.includes('builder')){
      this.jobType = 'builder'
			jobResponse = this.getBuilder()
		}
		else if (urlPath.includes('warranty')){
      this.jobType = 'warranty'
			jobResponse = this.getWarranty()
		}
		else if (urlPath.includes('repair')){
      this.jobType = 'repair'
			jobResponse = this.getRepair()
		}
		else if (urlPath.includes('reroof')){
      this.jobType = 'reroof'
			jobResponse = this.getReroof()
    }

    jobResponse.then( res => {
      this.job = res
      if (this.jobType === 'builder' || this.jobType === 'warranty') {
        // Get builder name
        this.builderService
          .getBuilder(this.job.BuilderID)
          .subscribe((res: any) => {
            this.builder = res.body
          })

        // Get community name
        this.communityService
          .getCommunity(this.job.CommunityID)
          .subscribe((res: any) => {
            this.community = res.body
          })
      }

      // Get crew leader name
      if (this.jobType === 'builder') {
				// console.log('Get crew leader name', this.job);
				if(this.job.CrewLeaderID != null) {
					this.employeeService.getEmployee(this.job.CrewLeaderID).subscribe( (result: any) => {
						// console.log('job crew leader', result);

						result.body.FullName = [result.body.FirstName, result.body.LastName].join(' ');
						this.crewLeader = result.body
					})
				}

				this.employeeService.getEmployees().subscribe( (result: any) => {
					let employees = result.body
							// .filter( employee => employee.IsCrewLeader )
							.map( employee => {
								return {
									text: `${ employee.FirstName } ${ employee.LastName }`,
									id: employee.ID
								}
							})

					this.crewLeaders = this.updateUsers(employees)
				})
			}
			this.checklistForm = this.formBuilder.group({
				type: 'Safety',
				comments: '',
				CrewLeaderID: [ this.job.CrewLeaderID, Validators.required ],
				gasCan: ['', Validators.required],
				compressor: ['', Validators.required],
				fireExtinguisher: ['', Validators.required],
				firstAidKit: ['', Validators.required],
				ladderAngle: ['', Validators.required],
				ladderTiedOff: ['', Validators.required],
				ladderHeight: ['', Validators.required],
				anchorPlacement: ['', Validators.required],
				anchorAttachment: ['', Validators.required],
				srlAttached: ['', Validators.required],
				srlCondition: ['', Validators.required],
				creativity: ['', Validators.required],
				harnessCondition: ['', Validators.required],
				hardHats: ['', Validators.required],
				safetyGlasses: ['', Validators.required],
				gloves: ['', Validators.required],
				otherEntries: this.formBuilder.array([ this.createItem() ]),
			});

			// Only Builder Jobs require a CrewLeaderID
			if ( this.jobType !== 'builder' ) {
				this.checklistForm.removeControl( 'CrewLeaderID' )
			}
    })

	}

	openModal(modal, windowClass) {
		// don't show modals if crew leader is logged in
		if(this.roleService.isCrewLeader) return

		this.modalService
			.open(modal, { ariaLabelledBy: "modal-basic-title", windowClass })
			.result.then(
				result => {

					for (let key in result) {
						const value = result[key]

						if( key === "CrewLeaderID") {
							this.updateCrewLeader( value )
						}

					}
				},
				reason => {
					// this.closeResult = this.getDismissReason(reason);
				}
			);
	}

	updateCrewLeader( id: string ) {
		this.checklistForm.controls[ 'CrewLeaderID' ].setValue( id )
		this.userService.getUser( id ).subscribe((result: any) => {
			let user = result.body
			this.crewLeader = user
		});
	}

  createItem(): FormGroup {
    return this.formBuilder.group({
      input: '',
      rating: '',
    });
  }

  addItem(): void {
    this.otherEntries = this.checklistForm.get('otherEntries') as FormArray;
    this.otherEntries.push(this.createItem());
  }

  removeItem(index): void {
    this.otherEntries.removeAt(index)
  }

  displayFilesOnUpload() {
    const input = document.querySelector('#fileUpload');
    // Create array from FileList
    this.builderFiles = Array.from((<HTMLInputElement>input).files)
  }

  get form() {
    return this.checklistForm.get('otherEntries')['controls']
  }

  handleFormSubmit() {
		this.submitted = true

		if (this.checklistForm.invalid) {
			this.alertService.error("Your form has errors. Make sure all inspection items have been checked")
			document.body.scrollIntoView({ behavior: "smooth" })
			this.submitted = false
			return
		}
    const formData = this.checklistForm.value
    // change form values from strings to integers
    for (const key in formData) {
      const value = formData[key]

			// Look at all fields except comments, type & CrewLeaderID
			if (
					key !== "comments" &&
					key !== 'type' &&
					key !== 'CrewLeaderID'
				) {

				if (key === "otherEntries") {

						formData[key].forEach(( value, index ) => {
							if(!value.input){
								formData[key].splice(index, 1)
							}
							else {
								value.rating = +value.rating
							}
						})

				}
				else {
					formData[key] = +value
				}
			}
    }

    this.inspectionsService
      .createSafetyChecklist(formData)
      .subscribe((data: any) => {
				let signatureFile
        // save digital signature as a File
				if(this.signatureBaseURL){
					signatureFile = this.createFileFromBaseURL(this.signatureBaseURL)
				}
				else {
					this.alertService.error("You must sign the inspection to submit it");
					document.body.scrollIntoView({ behavior: "smooth" });
					this.submitted = false
					return
				}

        this.filesService.uploadFile(signatureFile, data.ID, "Inspection").subscribe(( res: any ) => {
          // wait a second for the server to register the new files
          if( isArray(res) ){
            setTimeout(() => {
              data.SignatureFileID = res[0]
              this.inspectionsService.updateSafetyChecklist(data.ID, data).subscribe(( res: any ) => {})
            }, 1000)
          }
        })

        // If files were uploaded, send to server
        if (this.builderFiles.length) {
          // Iterate through each file in the array
          this.builderFiles.forEach(file => {
            // send array of files to file upload service function
            this.filesService.uploadFile(file, data.ID, "Inspection").subscribe(
              res => {
                console.log(res)
              },
              err => console.log(err)
            )
          })
        }

        // Add created inspection to Job, then redirect to job
        let jobType

				switch ( this.jobType ) {
					case 'builder':
						jobType = 'BuilderJob'
						break;
					case 'warranty':
						jobType = 'Warranty'
						break;
					case 'reroof':
						jobType = 'Reroof'
						break;
					case 'repair':
						jobType = 'Repair'
						break;
				}

        this.inspectionsService
          .addChecklistToJob( data.ID, this.jobID, jobType, data )
          .subscribe(( result: any ) => {
            this.router.navigate([ `${this.jobType}-jobs/${this.jobID}` ]);
          })
      },
        error => {
          this.alertService.error(error);

          document.body.scrollIntoView({ behavior: "smooth" });
        }
      )
	}

	updateUsers(users = []): Observable<Array<Select2OptionData>> {
		return Observable.create((obs) => {
			obs.next(users);
			obs.complete();
		});
	}
}
