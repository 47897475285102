export class Comment {
	ID: string;
	CreatedDate: string;
	ObjectID: string;
	ObjectType: string;
	CommenterID: string;
	CommentText: string;
	EditText: string;
	User: {
		Id: string;
		Email: string;
		Password: string;
		Active: boolean;
		resetToken: string;
		Role_Id: string;
		FullName: string;
		ActivityLogs: any;
		Role: string;
		UserSessions: any;
		Comments: any;

	}

}
