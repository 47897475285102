import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService } from '@/services/auth/auth.service';
import { AlertService } from '@/services/alert/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	loginForm: FormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;

  constructor(
	  private formBuilder: FormBuilder,
	  private route: ActivatedRoute,
	  private router: Router,
		private authService: AuthService,
		private alertService: AlertService
  ) {

		if( this.authService.currentUserValue ) {
			this.router.navigate(['/']);
		}

	}

  ngOnInit() {
		this.loginForm = this.formBuilder.group({
			UserName: ['', Validators.required],
			Password: ['', Validators.required]
		});

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

	get form() {
		return this.loginForm.controls;
	}

	scrollToFormError() {
		document.querySelector('.invalid-feedback').scrollIntoView({
			behavior: 'smooth',
			block: "center"
		})
	}

	onSubmit() {
		this.submitted = true;

		this.alertService.clear();

		if( this.loginForm.invalid ) {
			setTimeout(() => this.scrollToFormError(), 100)
			return;
		}

		this.loading = true;
		this.authService.login(this.form.UserName.value, this.form.Password.value)
			.pipe(first())
			.subscribe(
				data => {
					this.alertService.success('Login Successful');
					this.router.navigate([this.returnUrl]);
				},
				error => {
					this.alertService.error(error);
					this.loading = false;
				}
			)
	}
}
