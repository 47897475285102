import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { ActivatedRoute, ParamMap, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { UserService } from '@/services/user/user.service';
import { AlertService } from '@/services/alert/alert.service';
import { AuthService } from '@/services/auth/auth.service';



@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

	// HTML References
	@ViewChild('newPassword')
	newPasswordInput: ElementRef;

	@ViewChild('confirmPassword')
	confirmPasswordInput: ElementRef;

	@ViewChild('submitPassword')
	submitPassword: ElementRef;

	@ViewChild('requirements')
	passwordRequirements: ElementRef;

	@ViewChild('dataSlider')
	dataSlider: ElementRef;

	@ViewChild('strengthValue')
	strengthValue: ElementRef;

	sameValueElement: HTMLLIElement;
	instructionsElement: HTMLElement;


	// Properties
	patterns = {
		pattern0: /.{8,}/, // minimum 8 characters
		pattern1: /^(?=.*[A-z])(?=.*\d).*$/, // one letter, one number
		pattern2: /.*[A-Z]/, // one uppercase letter
		pattern3: /.*[-\/:;()$.,?!'*=_~\[\] ]/ // any of these symbols
	}

	matches = {
		0: false,
		1: false,
		2: false,
		3: false,
		sameValue: false
	};

	disableSubmit: boolean = true;
	disallowedChar: boolean = true;
	submitted: boolean = false;
	resetToken: string;

	newPasswordForm: FormGroup;

  constructor(
		private route: ActivatedRoute,
		private alertService: AlertService,
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private authService: AuthService
		) { }

  ngOnInit() {

		// Redirect if user is logged in
		if(this.authService.currentUserValue) {
			this.router.navigate(['/dashboard']);
		}

		this.newPasswordForm = this.formBuilder.group({
			Password: ''
		});

		this.resetToken = this.route.snapshot.queryParams.token;

	}

	ngAfterViewInit() {
	}

	allTrue(object) {
		for (var i in object) {
	        if (object[i] === false)
	        {
	        	return false;
	        }
	    }
	    return true;
	}

	inputState(input) {

		let label = input.labels[0];

		if( input.value !== '' ) {

			label.classList.add('active');
		}
		else {
			label.classList.remove('active');
		}
	}

	inputValidation(event) {
		let requirementsElement  = this.passwordRequirements.nativeElement,
				requirementsChildren = requirementsElement.children,
				value                = event.target.value,
				index                = 0,
				patterns             = this.patterns,
				matches              = this.matches,
				compareValue         = this.confirmPasswordInput.nativeElement.value;


		this.inputState(event.target);

		for( let pattern in patterns) {

			for( let i = 0; i < requirementsChildren.length; i++ ) {

				if( requirementsChildren[i].id === pattern ) {
					var selector = requirementsChildren[i];
				}
				if( requirementsChildren[i].id === 'pattern4' ) {
					 this.sameValueElement = requirementsChildren[i];
				}
				if(requirementsChildren[i].id === 'pattern5' ) {
					this.instructionsElement = requirementsChildren[i];
				}
			}

			if( patterns[pattern].test(value) ) {
				matches[index] = true;
				selector.classList.remove('color-alert');
				selector.classList.add('color-success');

			}
			else {
				matches[index] = false;
				selector.classList.remove('color-success');
				selector.classList.add('color-alert');
			}

			index++;
		}

		if( this.allTrue(matches) && !this.disallowedTest(value, compareValue) ) {
			this.disableSubmit = false;
		}
		else
		{
			this.disableSubmit = true;
		}

		this.disallowedHandler(value, compareValue);
		this.strengthMeter(matches);
		this.compareValues();

	}

	compareValues() {

		let	value           = this.newPasswordInput.nativeElement.value,
		sameValueElement    = this.sameValueElement,
		matches             = this.matches,
		compareValue        = this.confirmPasswordInput.nativeElement.value;

		this.inputState(event.target);

		if( value === compareValue && value !== '' ) {

			this.matches.sameValue = true;

			sameValueElement.classList.remove('color-alert');
			sameValueElement.classList.add('color-success');

		}
		else {
			this.matches.sameValue = false;
			sameValueElement.classList.remove('color-success');
			sameValueElement.classList.add('color-alert');

		}

		if( this.allTrue(matches) && !this.disallowedTest(value, compareValue) ) {
			this.disableSubmit = false;
		}
		else
		{
			this.disableSubmit = true;
		}

		this.disallowedHandler(value, compareValue);
		this.disallowedTest(value, compareValue);
		this.strengthMeter(matches);

	}

	disallowedTest(newValue, compareValue) {
		var disallowed    = /[^A-Za-z\d-\/:;()$.,?!'*=_~\[\] ]/g;

		return ( disallowed.test(newValue) || disallowed.test(compareValue) ) ? true : false;
	}

	disallowedHandler(newValue, compareValue) {

		var disallowed    = /[^A-Za-z\d-\/:;()$.,?!'*=_~\[\] ]/g;

		if( this.disallowedTest(newValue, compareValue) ) {
			var matches        = newValue.match(disallowed),
					compareMatches = compareValue.match(disallowed),
					chars          = '';

		 var matchResults = [...matches, ...compareMatches];

			chars = matchResults.join(', ');
			this.disallowedChar = false;
			this.instructionsElement.textContent = `Remove disallowed characters: ${chars}`;

		}
		else
		{
			this.disallowedChar = true;
		}

	}

	strengthMeter(matches) {

		let width = 0,
				sliderFill     = this.dataSlider.nativeElement,
				strengthValue  = this.strengthValue.nativeElement,
				labelText = 'Weak',
				colors    = {
					success: '#78BE20',
					warning: '#ed5102',
					alert:   '#B7312C'
				};

		for ( var i in matches ) {
			if ( i !== 'sameValue' ) {
				if( matches[i] === true ) {
					width += 25;
				}
			}
		}

		if( width < 0 )
		{
			width = 0;
		}
		else if( width <= 50 ) {
			sliderFill.style.backgroundColor = colors.alert;
			labelText = 'Weak';
		}
		else if( width <= 75 ) {
			sliderFill.style.backgroundColor = colors.warning;
			labelText = 'Weak';
		}
		else if( width === 100 ) {
			sliderFill.style.backgroundColor = colors.success;
			labelText = 'Strong';
		}

		let formattedWidth =  String(width);
		formattedWidth = width + '%';

		strengthValue.textContent = labelText;
		sliderFill.style.width = formattedWidth;

	}

	onSubmit() {
		this.submitted = true;

		if (this.newPasswordForm.invalid) {
			console.error('form submission invalid');
			return;
		}

		this.userService.newPassword(this.newPasswordForm.get('Password').value, this.resetToken)
			.subscribe(
				(data: any) => {
					this.alertService.success(`Password has been reset`, true);
					this.router.navigate(['login']);
				},
				error => {
					this.alertService.error(error);

					document.body.scrollIntoView({behavior: "smooth"});
				}
			);
	}

}
