import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'main-nav-link',
	templateUrl: './link.component.html',
	styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit, AfterViewChecked {

	@Input() route: string;
	@Input() icon: string;
	@Input() text: string;

	currentURL: string = ''

	constructor(private router: Router) {
		router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.currentURL = ''
			}
		});
	}

	ngOnInit() {
	}

	ngAfterViewChecked() {
		if (!this.currentURL) {
			setTimeout(() => this.currentURL = this.router.url)
		}
	}

}
