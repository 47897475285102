import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

// Custom Modules
import { AppRoutingModule } from './app-routing.module';
import { AppConfigModule } from './app-config.module';
import { DashboardModule } from "@/views/dashboard/dashboard.module";

// Helpers/Services
import { JwtInterceptor, ErrorInterceptor } from './helpers';

// Components
import { AppComponent } from './app.component';

import { AlertComponent } from '@/components/alert/alert.component';
import { CardComponent } from '@/components/card/card.component';

import { LinkComponent } from '@/components/main-navigation/link/link.component';
import { LinkParentComponent } from '@/components/main-navigation/link-parent/link-parent.component';
import { LoginComponent } from '@/views/login/login.component';
import { LogoComponent } from '@/components/logo/logo.component';

import { MainNavigationComponent } from '@/components/main-navigation/main-navigation.component';
import { DatatableComponent } from '@/components/datatable/datatable.component';

import { MobileHeaderComponent } from '@/components/mobile-header/mobile-header.component';

import { NewPasswordComponent } from '@/views/new-password/new-password.component';
import { PageNotFoundComponent } from '@/views/page-not-found/page-not-found.component';
import { PasswordResetComponent } from '@/views/password-reset/password-reset.component';

import { SidebarComponent } from '@/components/sidebar/sidebar.component';
import { SubMenuComponent } from '@/components/main-navigation/sub-menu/sub-menu.component';
// import { PipeDecorator } from '@angular/core/src/metadata/directives';

@NgModule({
	declarations: [
		AlertComponent,
		AppComponent,
		CardComponent,
		LinkComponent,
		LinkParentComponent,
		LoginComponent,
		LogoComponent,
		MainNavigationComponent,
		MobileHeaderComponent,
		NewPasswordComponent,
		PageNotFoundComponent,
		PasswordResetComponent,
		SidebarComponent,
		SubMenuComponent,
		DatatableComponent
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		DashboardModule,
		FormsModule,
		HttpClientModule,
		NgbModule,
		ReactiveFormsModule,
		AppConfigModule,
		DataTablesModule,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
