import { Component, Input, Output, OnInit, EventEmitter, HostBinding } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'main-nav-link-parent',
  templateUrl: './link-parent.component.html',
  styleUrls: ['./link-parent.component.scss']
})
export class LinkParentComponent implements OnInit {

	@Input() isCollapsed : boolean = true;

	@Input() text: string;
	@Input() subID: string;
	@Input() icon: string;
	@Input() route: string;
	@Input() children: any[];

	activeParent: boolean = false

	@Output() isCollapsedChange = new EventEmitter<boolean>();


	constructor(
		private router: Router
	) {

		router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				if(this.children) {
					const isParent = this.children.find(child => child.route === this.router.url)
					this.activeParent = isParent ? true : false
				}
			}
		});

	}

	ngOnInit() {}

	toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
		this.isCollapsedChange.emit(this.isCollapsed);
	}

}
