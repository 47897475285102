export class AddressValues {
  email : string
	name  : string
}

export class Email{
	from            : AddressValues
	replyTo         : AddressValues
	to              : AddressValues[]
	cc              : AddressValues[]
	bcc             : AddressValues[]
	subject         : string
	body            : string
	type            : string
	relatedObjectId : string
	attachmentFiles : Array<string>

	constructor() {
		this.from            = new AddressValues()
		this.replyTo         = new AddressValues()
		this.to              = []
		this.cc              = []
		this.bcc             = []
		this.attachmentFiles = []
		this.relatedObjectId = ''
	}
}
