import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
	@Input() url         : string = '#';
	@Input() linkTitle   : string = 'Click to View';
	@Input() title       : string = '';
	@Input() description : string = '';
	@Input() icon        : string = '';

	@Input() onClose = ''

  constructor() { }

  ngOnInit() {
  }

}
