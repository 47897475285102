import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

	endpoint = 'alert'

	constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) { }

  private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		}
		else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}

		// return an observable with a user-facing error message
		return throwError("Something bad happened; please try again later.");
	}

	public getAlerts( page: number = null, count: number = null, objectType: string = '', alertType: number = null ){
		const url = `${this.config.apiUrl}/${this.endpoint}/list?page=${page}&count=${count}&objectType=${objectType}&alertType=${alertType}`;

    return this.http.get(url, { observe: 'response' })
      .pipe(
        catchError(this.handleError)
      );
	}

	public getAlertsDatatable(data){
		const url = `${this.config.apiUrl}/${this.endpoint}/datatable`;

		return this.http.post(url, data)
			.pipe(
				catchError(this.handleError)
			);
}
}
