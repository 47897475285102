import { Component, OnChanges } from '@angular/core';
import {
	Router,
	Event,
	NavigationStart,
	NavigationEnd,
	NavigationCancel,
	NavigationError
} from '@angular/router';

import { AuthService } from '@/services/auth/auth.service';

import { Role } from '@/models/role';
import { User } from '@/models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title    = 'SterlingRoofing';
	loggedIn = false;

	currentUser  : User
	sidebarOpen  : boolean = false;
	routeLoading : boolean = false

	constructor(
		private authService: AuthService,
		private router: Router
	) {
		this.loginCheck();

		this.router.events.subscribe((event: Event) => {
			this.loginCheck();
		});
		this.authService.currentUser.subscribe(user => this.currentUser = user);

		this.router.events.subscribe((event: Event) => {
			switch (true) {

				case event instanceof NavigationStart: {
					this.routeLoading = true;
					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.routeLoading = false;
					break;
				}
				default: {
					break;
				}
			}
		})
	}

	get isAdmin() {
		return this.currentUser && this.currentUser.role.name === Role.Admin;
	}

	get isSupervisor() {
		return this.currentUser && this.currentUser.role.name === Role.Supervisor;
	}

	get isCrewLeader() {
		return this.currentUser && this.currentUser.role.name === Role.CrewLeader;
	}

	loginCheck() {
		const currentUser = this.authService.currentUserValue;

		this.loggedIn = currentUser ? true : false;
	}

	toggleSidebar() {
		this.sidebarOpen = !this.sidebarOpen;
		return false;
	}

	closeSidebar() {
		if(this.loggedIn) {
			if( this.sidebarOpen ) {
				this.sidebarOpen = !this.sidebarOpen;
				return false;
			}
		}
	}
}
