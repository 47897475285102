import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { User } from '@/models/user';

import { AuthService } from '@/services/auth/auth.service';

@Component({
  selector: 'main-nav-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit {

  currentUser: User

	@Input() children : any[];
	@Input() id: string;
	@Input() isCollapsed: boolean;

	@Output() isCollapsedChange = new EventEmitter<boolean>();

  constructor(
		private authService: AuthService,
		private router: Router
	)
	{

		// Ensures that sub menu stays open on refresh
		router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				if(this.children) {
					const isParent = this.children.find(child => child.route === this.router.url)
					if(isParent && this.isCollapsed) {
						this.toggleCollapse()
					}
				}
			}
		});
    this.authService.currentUser.subscribe(user => this.currentUser = user);
  }

  ngOnInit() {
  }

	toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
		this.isCollapsedChange.emit(this.isCollapsed);
	}
}
