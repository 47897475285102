import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from "@angular/platform-browser";

import { JobsService } from '@/services/jobs/jobs.service';
import { BuildersService } from '@/services/builders/builders.service';
import { CommunitiesService } from '@/services/communities/communities.service';
import { UserService } from '@/services/user/user.service';
import { InspectionsService } from '@/services/inspections/inspections.service';
import { FilesService } from '@/services/files/files.service';
import { AlertService } from '@/services/alert/alert.service';
import { RoleService } from '@/services/role/role.service';

import { BuilderJob } from '@/models/builder-job';
import { Community } from '@/models/community';
import { User } from '@/models/user';
import { Builder } from '@/models/builder';
import { SafetyInspection } from '@/models/safety-inspection';

import { Select2OptionData } from 'ng-select2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-safety-inspection-view',
  templateUrl: './safety-inspection-view.component.html',
  styleUrls: ['./safety-inspection-view.component.scss']
})
export class SafetyInspectionViewComponent implements OnInit {
  @ViewChild('inspectionTable')
	inspectionTable: ElementRef;

	@ViewChild('filePreviewModal')
	filePreviewModal: ElementRef;

  inspection           : SafetyInspection
  jobID                : string
  jobType              : string
  checklistID          : string
  job                  : BuilderJob
  community            : Community
  builder              : Builder
  crewLeader           : User
  crewLeaders 				 : Observable<Array<Select2OptionData>>
  checklistForm        : FormGroup
  otherEntries         : FormArray
	uploadError          : string
  inspectionFiles      : Array<object>
  inspectionSignature  : string | ArrayBuffer
	modalPreviewFile		 : any;
	modalPreviewFileType : string;
	modalPreviewFileName : string;
	modalPreviewFileID   : string;

  uploadResponse = { status: '', message: '', filePath: '' }
  selectOptions  = [
    { name: "N/A", value: 0 },
    { name: "Non-compliant", value: 1 },
    { name: "Compliant", value: 2 },
	]

	select2Options  = {
		theme: "bootstrap4",
		width: "100%"
	}

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private jobService: JobsService,
    private builderService: BuildersService,
    private communityService: CommunitiesService,
    private userService: UserService,
		public roleService: RoleService,
    private modalService: NgbModal,
    private inspectionsService: InspectionsService,
    private alertService: AlertService,
    private filesService: FilesService,
		private domSanitizer : DomSanitizer,
  ) {
    this.jobID       = this.route.snapshot.paramMap.get("id")
    this.checklistID = this.route.snapshot.paramMap.get("checklist_id")
  }

  async getWarranty() {
    const data = await this.jobService.getWarrantyJob(this.jobID).toPromise()
    return data.body
  }

  async getBuilder() {
    const data = await this.jobService.getBuilderJob(this.jobID).toPromise()
    return data.body
  }

  async getRepair() {
    const data = await this.jobService.getRepairJob(this.jobID).toPromise()
    return data.body
  }

  async getReroof() {
    const data = await this.jobService.getReroofJob(this.jobID).toPromise()
    return data.body
  }

  ngOnInit() {
		let jobResponse
		const urlPath = location.href

    if ( urlPath.includes( 'builder' )) {
      this.jobType = 'builder'
      jobResponse = this.getBuilder()
		}
		else if ( urlPath.includes( 'warranty' )) {
      this.jobType = 'warranty'
      jobResponse = this.getWarranty()
		}
		else if ( urlPath.includes( 'repair' )) {
      this.jobType = 'repair'
      jobResponse = this.getRepair()
		}
		else if ( urlPath.includes( 'reroof' )) {
      this.jobType = 'reroof'
      jobResponse = this.getReroof()
    }

    jobResponse.then(res => {
      this.job = res
      if (this.jobType === 'builder' || this.jobType === 'warranty') {
        // Get builder name
        this.builderService
          .getBuilder(this.job.BuilderID)
          .subscribe((res: any) => {
            this.builder = res.body
          })

        // Get community name
        this.communityService
          .getCommunity(this.job.CommunityID)
          .subscribe((res: any) => {
            this.community = res.body
          })
      }

      // get only users who are crew leaders and put in dropdown
			this.userService.getUsers( 3 ).subscribe(( result: any ) => {
				const users = result.body.data.map( user => {
					const text = user.FullName ? user.FullName : user.Email
					const id   = user.Id

					return { id, text }
				})
				this.crewLeaders = this.updateUsers( users )
			})
    })

    this.checklistForm = this.formBuilder.group({
      Type: 'Safety',
      Comments: '',
      CrewLeaderID: '',
      GasCan: '',
      Compressor: '',
      FireExtinguisher: '',
      FirstAidKit: '',
      LadderAngle: '',
      LadderTiedOff: '',
      LadderHeight: '',
      AnchorPlacement: '',
      AnchorAttachment: '',
      SRLAttached: '',
      SRLCondition: '',
      Creativity: '',
      HarnessCondition: '',
      HardHats: '',
      SafetyGlasses: '',
      Gloves: '',
      OtherEntries: this.formBuilder.array([]),
    });

		// Only Builder Jobs require a CrewLeaderID
		if ( this.jobType !== 'builder' ) {
			this.checklistForm.removeControl( 'CrewLeaderID' )
		}

    this.inspectionsService
      .getSafetyChecklist(this.checklistID)
      .subscribe((data: any) => {
        this.inspection = data.body
        this.inspectionFiles = this.inspection.Files.filter( file => file.ID !== this.inspection.SignatureFileID )

        if (this.inspection.SignatureFileID)
          this.displaySignature(this.inspection.SignatureFileID)
        // change integer values to strings to populate radio buttons
        for (const key in this.inspection) {
          if (this.inspection.hasOwnProperty(key)) {
            const value = this.inspection[key]
            if (typeof value === 'number' || key === 'Comments') {
              this.checklistForm.get(key).setValue(`${value}`)
            }
            if(key === 'OtherEntries' && value){
              if (value) {
                value.forEach(val => {
                  this.createItemWithValues(val.Input, `${val.Rating}`)
                })
              }
            }
          }
				}
				// Get crew leader name
				if ( this.jobType === 'builder' ) {
					this.userService
						.getUser( this.inspection.CrewLeaderID )
						.subscribe(( res: any ) => {
							this.crewLeader = res.body
						})
				}
      })
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      Input: '',
      Rating: '',
    });
  }

  addItem(): void {
    this.otherEntries = this.checklistForm.get('OtherEntries') as FormArray;
    this.otherEntries.push(this.createItem());
  }

  createItemWithValues(input: string, rating: string): void {
    this.otherEntries = this.checklistForm.get('OtherEntries') as FormArray;
    this.otherEntries.push(
      this.formBuilder.group({
        Input: input,
        Rating: rating
      })
    )
  }

  removeItem(index): void {
    this.otherEntries.removeAt(index)
  }

  updateFiles() {
    this.inspectionsService.getSafetyChecklist(this.inspection.ID).subscribe((result: any) => {
      this.inspectionFiles = result.body.Files.filter(file => file.ID !== this.inspection.SignatureFileID)
    })
  }

  onDeleteFile(event: Event, fileID: string) {
    event.preventDefault()
    if (window.confirm("Do you really want to delete this file?")) {
      this.filesService.deleteFile(fileID).subscribe((result: any) => {
        this.updateFiles()
      })
    }
  }

  setPreviewFile( fileURL ){
		if(this.modalPreviewFileType.includes('image')){
			this.modalPreviewFile = fileURL
		}else{
			this.modalPreviewFile = this.domSanitizer.bypassSecurityTrustResourceUrl(fileURL)
		}
		this.openModal( this.filePreviewModal, 'image-pdf' )
	}

	downloadPreviewFile(){
		this.filesService.downloadFile( this.modalPreviewFileID ).subscribe(( result: Blob ) => {
			// create a fake anchor element to force browser to download
			const anchor = document.createElement('a')
			anchor.style.display = "none"
			document.body.appendChild(anchor)
			// Set file name attribute
			anchor.setAttribute("download", this.modalPreviewFileName)
			// create new blob to change the type to the file's type
			const blob = new Blob([result], {type: this.modalPreviewFileType})
			// Create file URL from Blob object
			const url  = window.URL.createObjectURL(blob)
			// set href attribute to be url
			anchor.setAttribute("href", url)
			// force click on anchor to download
			anchor.click()
			// Cleanup
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		})
	}

	getFile( event, fileID, fileType, fileName ){
		event.preventDefault()
		this.modalPreviewFileName = fileName
		this.modalPreviewFileType = fileType
		this.modalPreviewFileID   = fileID

		this.filesService.downloadFile( fileID ).subscribe(( result: Blob ) => {
			// create new blob to change the type to the file's type
			const blob = new Blob([result], {type: fileType})
			// create base64 url to display in modal
			const reader = new FileReader()
			reader.readAsDataURL(blob)
			reader.onloadend = () => {
				var base64data = reader.result
				this.setPreviewFile(base64data)
			}
		})
	}

  displaySignature(id){
    this.filesService.getFile(id).subscribe(( res: any) => {
      const file = res.body
      const fileType = file.Type
      this.filesService.downloadFile(id).subscribe((result: Blob) => {
        // create new blob to change the type to the file's type
        const blob = new Blob([result], { type: fileType })
        // get Base64 url and display as image
        var reader = new FileReader()
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.inspectionSignature = reader.result
        }
      })
    })
  }

  displayFilesOnUpload() {
    const input = document.querySelector('#fileUpload');
    // Create array from FileList
    const filesArray = Array.from((<HTMLInputElement>input).files)
    // Iterate through each file in the array and send to server endpoint
    filesArray.forEach(file => {
      // send array of files to file upload service function
      this.filesService.uploadFile(file, this.inspection.ID, "Inspection").subscribe(
        res => {
          this.uploadResponse = res
          if (res.message == '100') {
            // wait a second for the server to register the new files
            setTimeout(() => {
              this.updateFiles()
              this.alertService.success(`File uploaded successfully!`, true);
            }, 1000)
          }
        },
        err => {
          this.uploadError = err
          this.alertService.error(`File upload failed - ${err}`, true);
        }
      )
    })
  }

  openModal(modal, windowClass = '') {
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', windowClass })
      .result.then(
        result => {
          for (let key in result) {
            var value = result[key];

            if ( value == 1 || value == 2 || value == 3 ) {
              value = +value
            }
            if (key === "OtherEntries") {
              value.forEach(( val, index ) => {
                val.Rating = +val.Rating
              })
            }
            this.inspection[key] = value
          }

          this.inspectionsService.updateSafetyChecklist(this.inspection.ID, this.inspection)
            .subscribe(
              (data: any) => {
								this.updateCrewLeader( this.inspection[ 'CrewLeaderID' ] )
								this.alertService.success(`Inspection Updated!`, true)
          			document.body.scrollIntoView({ behavior: "smooth" })
              },
              error => {
                this.alertService.error(error);
                document.body.scrollIntoView({ behavior: "smooth" });
              });
        },
        reason => {
          // this.closeResult = this.getDismissReason(reason);
        }
      );
  }

  get form() {
    return this.checklistForm.get('OtherEntries')['controls']
  }

  handleFormSubmit() {
		let data = this.checklistForm.value

    for (let key in data) {
      let value = data[key];

      if (key === "OtherEntries") {
        value.forEach((val, index) => {
          if (!val.Input) {
            data[key].splice(index, 1)
          } else {
            val.Rating = +val.Rating
          }
        })
			}
			else {
				// force all values to an integer that arent Type, Comment & CrewLeaderID
				if (
					key !== 'Type' &&
					key !== 'Comments' &&
					key !== 'CrewLeaderID'
				){
					value = +value
				}
			}
      data[key] = value
		}

		data = { ...this.inspection, ...data}

    this.inspectionsService.updateSafetyChecklist(this.inspection.ID, data)
      .subscribe(
        (data: any) => {
          this.alertService.success(`Inspection Updated!`, true)
          document.body.scrollIntoView({ behavior: "smooth" })
        },
        error => {
          this.alertService.error(error);
          document.body.scrollIntoView({ behavior: "smooth" });
        });
	}

	updateUsers(users = []): Observable<Array<Select2OptionData>> {
		return Observable.create((obs) => {
			obs.next(users);
			obs.complete();
		});
	}

	updateCrewLeader( id: string ) {
		this.userService
			.getUser( id )
			.subscribe(( res: any ) => {
				this.crewLeader = res.body
			})
	}
}
