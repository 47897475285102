import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
	providedIn: 'root'
})
export class FilesService {
	endpoint = 'file';

	constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) {
	}

	public getFile(id: string) {
		let url = `${this.config.apiUrl}/${this.endpoint}/get/${id}`;

		return this.http.get(url, {observe: 'response'})
			.pipe(
				catchError(this.handleError)
			);
	}

	public uploadFile(data: any, id: string, jobType: string, ForSMS: boolean = false) {
		var formData = new FormData();
		formData.append("file", data)

		let url = `${this.config.apiUrl}/${this.endpoint}/upload?objectID=${id}&label=${data.name}&type=${data.type ? data.type : 'unknown'}`;

		if ( ForSMS ) {
			url += `&objectType=SMS&forSms=true`
		}
		else {
			url += `&objectType=${ jobType }`
		}

		return this.http.post<any>(url, formData, {
			reportProgress: true,
			observe: 'events'
		}).pipe(map((event) => {

			switch (event.type) {

				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return { status: 'progress', message: progress };

				case HttpEventType.Response:
					return event.body;
				default:
					return `Unhandled event: ${event.type}`;
			}
		}))
	}

	public downloadFile(fileID){
		let url = `${this.config.apiUrl}/${this.endpoint}/download/${fileID}`;

    return this.http.get(url, {
			responseType: 'blob'
		}).pipe(
      catchError(this.handleError)
    )
	}

	public deleteFile(fileID: string){
		let url = `${this.config.apiUrl}/${this.endpoint}/delete/${fileID}`;

		return this.http.delete(url, {observe: 'response'})
			      .pipe( catchError(this.handleError) )
	}

	private handleError(error: any, context: any = '') {
		console.error('Invalid Request')

		let message = `Failure: ${error}`;

		// if (context) {
		// 	message = `${message} ${context}`;
		// }

		// return an observable with a user-facing error message
		return throwError(message);
	}
}
