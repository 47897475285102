import { Component, OnInit, Input } from '@angular/core';

import { ActivityLogService } from "@/services/activity-log/activity-log.service";
import { UserService } from "@/services/user/user.service";
import { BuildersService } from "@/services/builders/builders.service";
import { ContactsService } from "@/services/contacts/contacts.service";
import { CommunitiesService } from "@/services/communities/communities.service";

import { User } from "@/models/user";

import { Observable, of} from "rxjs";

@Component({
  selector: 'activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {

	@Input() type: string = '';
	@Input() id: string = '';
	log = null;
	// pagination variables
	entriesPerPage: number = 20;
	currentPage: number = 1;

  constructor(
		private activityLogService: ActivityLogService,
		private userService: UserService,
		private buildersService: BuildersService,
		private contactsService: ContactsService,
		private communitiesService: CommunitiesService

	) { }

  ngOnInit() {

		this.activityLogService.getLog(this.type, this.id).subscribe((result: any) => {
			var log  = result.body,
					users: User[] = [];

			log = log.reverse();
			this.log = log;

			// Loop through object to get users/format date
			log.forEach( ( item, index ) => {
				let time = this.formatTimestamp(item.Timestamp),
						date = this.formatDate(item.Timestamp),
						changed = item.FieldChanged;

				users.push(item.UserResponsibleID);

				item.Timestamp = time;
				item.date      = date;

				if( changed === "Builder" || changed === "BuilderID") {
					this.getBuilder(item);
				}
				else if( changed === "BuilderConstructionManager" || changed === "BuilderCustomerCareRepID" || changed === "BuilderCustomerCareRep_ID") {
					this.getContact(item);
				}
				else if( changed === "Community" ) {
					this.getCommunity(item);
				}

				if( changed !== "Lot1" && changed !== "Lot2" ) {
					if(changed === 'BuilderCustomerCareRep_ID') {
						item.FieldChanged = 'Builder Customer Care Rep'
					}
					else {
						item.FieldChanged = changed.replace(/([a-z])([A-Z])[\W_]+/g, '$1 $2');
					}
				}

			});

			// Iterating through all users until/if a get single user endpoint is set up
			this.userService.getUsers().subscribe((result: any) => {
				var allUsers = result.body;
				var userNames = [];

				users.forEach( logUser => {
					allUsers.data.forEach( user => {

						if( logUser === user.Id ) {
							userNames.push(user.Email);
						}
					});
				});

				log.forEach( ( item, i ) => {

					item.userResponsible = userNames[i];

					this.formatUsers(item, allUsers);

					// Formatting to add a space in between the job type and "job"
					// Build User Message
					if( item.FieldChanged === "Created Date" ) {
						let job = this.type,
								index = job.indexOf("Job");

						if(item.Description.toLowerCase().includes("file uploaded")){
							item.userMessage = 'New file uploaded'
						}else{
							if(index !== -1) {
								job = job.slice(0, index) + " " + job.slice(index);
							}
							else {
								job = job + " Job";
							}
							item.userMessage = job + " " + "created";
							item.userMessage.toString();
						}

					}
					else if( item.FieldChanged == 'SentEmail' || item.FieldChanged == 'SentSMS' ) {
						item.userMessage = item.FieldChanged + " " + item.Description;
					}
					else {
						if(item.Description.toLowerCase().includes("file removed")){
							item.userMessage = 'File deleted'
						}else{
							item.userMessage = item.FieldChanged + " changed to " + item.NewValue;
						}
					}

				});

			});

		});

	}

	pageChanged(newPage: number){
		this.currentPage = newPage
	}

	// Function to format logs timestamp to human readable format
	formatTimestamp(timestamp) {

		var dateObject = new Date(timestamp),
				time = [dateObject.getHours(), dateObject.getMinutes()],
				suffix = ( time[0] < 12 ) ? "AM" : "PM";

		time[0] = ( time[0] < 12 ) ? time[0] : time[0] - 12;
		time[0] = time[0] || 12;

		let stringTime = time.map(String);

		for ( var i = 1; i < 2; i++ ) {
			if ( time[i] < 10 ) {
				stringTime[i] = "0" + time[i].toString();
			}
		}

		return stringTime.join(":") + " " + suffix;
	}

	formatDate(timestamp) {
		var dateObject = new Date(timestamp),
		date = [ dateObject.getMonth() + 1, dateObject.getDate(), dateObject.getFullYear() ];
		return date.join("/");
	}

	// Function to format the users into friendly text
	formatUsers(item, allUsers) {

		allUsers.data.forEach( user => {

			if( item.NewValue === user.Id ) {
				item.NewValue = user.FullName || user.Email;

				if( item.FieldChanged === "CrewLeader ID" ) {
					item.FieldChanged = "Crew Leader";
				}
				else if( item.FieldChanged === "Supervisor ID" ) {
					item.FieldChanged = "Supervisor";
				}

			}
		});

	}

	getBuilder(item) {

		this.buildersService.getBuilder(item.NewValue).subscribe((result: any) => {

			 let builder = result.body;

			 if( builder ) {
				 item.NewValue = builder.CompanyName;
				 item.userMessage = item.FieldChanged + " changed to " + item.NewValue;
			 }
		});

	}

	getContact(item) {

		this.contactsService.getContact(item.NewValue).subscribe((result: any) => {
			let contact = result.body;
			if( contact ) {
				item.NewValue = contact.Name;
				item.userMessage = item.FieldChanged + " changed to " + item.NewValue;
			}
		});

	}

	getCommunity(item) {

		this.communitiesService.getCommunity(item.NewValue).subscribe((result: any) => {
			let community = result.body;
			if( community ) {
				item.NewValue = community.Name;
				item.userMessage = item.FieldChanged + " changed to " + item.NewValue;
			}
		});
	}

}

