import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

	constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) { }

  	private handleError(error: HttpErrorResponse, obs) {

		console.error('raw error:', error, 'headers:', obs, obs.status, obs.statusText, obs.message);

		if (error.error instanceof ErrorEvent) {
		  // A client-side or network error occurred. Handle it accordingly.
		  console.error('An error occurred:', error.error.message);
		}
		else {
		  // The backend returned an unsuccessful response code.
		  // The response body may contain clues as to what went wrong,
		  console.error(
		    `Backend returned code ${error.status}, ` +
		    `body was: ${error.error}`);
		}

		// return an observable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}

	public getLog(type: string, id: string) {
		let endpoint = 'activitylog',
				url      = `${this.config.apiUrl}/${endpoint}/list?objectType=${type}&objectID=${id}`;

		console.log(url);

		return this.http.get(url, { observe: 'response' })
		.pipe(
			catchError(this.handleError)
		);
	}

	public addLogEntry(type: string, id: string, description: string) {
		let endpoint = 'activitylog',
				url      = `${this.config.apiUrl}/${endpoint}/add`;

		console.log(url);
		let data = {
			'objectType' : type,
			'objectId' : id,
			'description' : description
		};

		return this.http.post(url, data).pipe(catchError(this.handleError))
	}
}
