import { Component, OnInit, ElementRef, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';

import { MainNavItems } from './main-navigation-items';

import { AuthService } from '@/services/auth/auth.service';

import { User } from '@/models/user';

@Component({
	selector: 'main-navigation',
	templateUrl: './main-navigation.component.html',
	styleUrls: ['./main-navigation.component.scss']
})

export class MainNavigationComponent implements OnInit, AfterViewInit {
	@ViewChild('activeSubNav')
	activeSubNav: ElementRef

	menuItems = [];
	currentUser: User

	constructor(
		private authService: AuthService
	) {
		this.menuItems = MainNavItems;
		this.authService.currentUser.subscribe(user => this.currentUser = user);
	}

	ngOnInit() {
		console.log(this.currentUser)
	}

	ngAfterViewInit() {

	}

	collapseState(value: Event, item) {
		this.menuItems.forEach( menuItem => menuItem.isCollapsed = true )
		item.isCollapsed = value;
	}

	logout() {
		this.authService.logout();
	}
}
