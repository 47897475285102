import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { JobModule } from '@/modules/job.module'
import { AlertsModule } from '@/views/alerts/alerts.module';

import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';

import { DashboardComponent } from '@/views/dashboard/dashboard.component';

export const routes: Routes = [
	{ path: '', component: DashboardComponent },
];

@NgModule({
	declarations: [
		DashboardComponent,
	],
	imports: [
		CommonModule,
		NgbAccordionModule,
		DataTablesModule,
		JobModule,
		AlertsModule,
		RouterModule.forChild(routes)
	]
})
export class DashboardModule { }
