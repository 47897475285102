import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';

import { JobsService } from '@/services/jobs/jobs.service';

@Component({
  selector: 'datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {

	@Input() title : string = '';
	@Input() showFooter : boolean = true;
	@Input() rowsPerPage : number = 10;
	@Input() viewRoute : string = '';
	@Input() viewText : string = 'View';
	@Input() dataType : string;

	dtOptions : DataTables.Settings = {};
	dtTrigger : Subject<any> = new Subject();
	defaultDTOptions = {};
	tableData = [];

  constructor(
	  private jobsService: JobsService,
  )
  {
	  this.defaultDTOptions = {
	    pagingType  : 'simple_numbers',
	    lengthChange: false,
	    info: this.showFooter,
	    paging: this.showFooter,
			initComplete: () => {
				let searchLabels = document.querySelectorAll('.dataTables_filter > label');

				searchLabels.forEach((label) => {
					label.setAttribute('aria-label', 'Search/Filter Table');
				});
			}
	  }
	}

  ngOnInit() {
	  this.getData(this.dataType);
  }

  initBuilderJobsTable() {
		let options = Object.assign({
			data: this.tableData,
			columns: [
				{
					name: 'Builder',
					data: 'builder.companyName'
				},
				{
					name: 'Community',
					data: 'community.name'
				},
				{
					name: 'Lot',
					data: 'lot1'
				},
				{
					name: 'Community Manager',
					data: 'community.communityManagerName'
				},
				{
					name: 'Checklist',
					data: ''
				},
				{
					name: 'Detail',
					data: ''
				}
			]
		}, this.defaultDTOptions);

		this.dtOptions = options;
		this.dtTrigger.next();

		console.log(this.dtOptions)

  }

  getData(type) {
	  switch(type) {
		  case 'builderjobs':
		    this.jobsService.getBuilderJobs().subscribe((result: any) => {
			  	this.tableData = result.body;

			  	this.initBuilderJobsTable();
			  });
		  break;
	  }
  }
}
