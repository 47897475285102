import { Component, OnInit, Input, ElementRef, ViewChild, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, NgControl } from '@angular/forms';

import { AuthService } from '@/services/auth/auth.service';
import { UtilitiesService } from '@/services/utilities/utilities.service';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface CustomWindow extends Window {
    transformersPipeline: any;
}
// import { pipeline } from '@xenova/transformers';

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    @Input('open') open: boolean;
    modalReference: NgbModalRef;
    translateModalElement: any;
    window: CustomWindow;

    @ViewChild('translateModalElement')
    translateElementsModal: ElementRef;
    translateForm: FormGroup;

    constructor(
        private authService: AuthService,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
		private utilities: UtilitiesService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.window = <any>this.document.defaultView;
     }

    ngOnInit() {
        this.utilities.loadTransformersJsModule(this.renderer);

        this.translateForm = this.formBuilder.group({
            translateInput: '',
            translateOutput: ''
        });

    }

    openModal(event) {
        event.preventDefault();
        event.stopPropagation();

        let modal = this.translateElementsModal;
        let windowClass = 'c-translate-data-modal';
        this.modalReference = this.modalService.open(modal, { ariaLabelledBy: "modal-basic-title", windowClass })
        this.modalReference.result.then(
            result => {
                `Closed with ${result}`
            },
            reason => {
                `Dismissed with ${reason}`
            }
        )
    }

    async translate() {
        this.translateForm.get('translateOutput').setValue('Working...');
        let pipe = await this.window.transformersPipeline('translation', 'Xenova/opus-mt-en-es');
        var input = this.translateForm.get('translateInput').value;
        let out = await pipe(input);
        if(typeof out[0] != 'undefined' && typeof out[0].translation_text == 'string') {
            this.translateForm.get('translateOutput').setValue(out[0].translation_text);
        } else {
            this.translateForm.get('translateOutput').setValue('Error');
        }
    }

    logout() {
        this.authService.logout();
    }

}
