import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
	endpoint: string = 'email'

  constructor(
		private http: HttpClient,
		@Inject(APP_CONFIG) private config: AppConfig
	) { }

	private handleError(error: any, context: any = '') {
		console.error('Invalid Request')

		let message = `Failure: ${error}`;

		// if( context ) {
		// 	message = `${message} ${context}`;
		// }

		// return an observable with a user-facing error message
		return throwError(message);
	}

	public sendEmail( emailData: any ){
		const url = `${this.config.apiUrl}/${this.endpoint}/sendasync`

		return this.http.post(url, emailData)
			.pipe(
				catchError(error => this.handleError(error, 'Check your submisision and try again.') )
			);
	}
}
