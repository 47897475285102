import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { AuthGuard } from '@/guards/auth/auth.guard';

import { DashboardComponent } from '@/views/dashboard/dashboard.component';
import { PageNotFoundComponent } from '@/views/page-not-found/page-not-found.component';
import { LoginComponent } from '@/views/login/login.component';
import { PasswordResetComponent } from '@/views/password-reset/password-reset.component';
import { NewPasswordComponent } from '@/views/new-password/new-password.component';

import { Role } from '@/models/role';

const routes: Routes = [
	{
		path: 'incidents',
		canActivate: [AuthGuard],
		loadChildren: '@/views/incidents/incidents.module#IncidentsModule'
	},
	{
		path: 'dashboard',
		canActivate: [AuthGuard],
		loadChildren: '@/views/dashboard/dashboard.module#DashboardModule'
	},
	{
		path: 'builders',
		canActivate: [AuthGuard],
		loadChildren: '@/views/builders/builders.module#BuildersModule'
	},
	{
		path: 'communities',
		canActivate: [AuthGuard],
		loadChildren: '@/views/communities/communities.module#CommunitiesModule',
		data: { roles: [Role.Admin, Role.Supervisor, Role.CrewLeader] }
	},
	{
		path: 'reroof-jobs',
		loadChildren: '@/views/reroof-jobs/reroof-jobs.module#ReroofJobsModule',
		canActivate: [AuthGuard],
		data: { roles: [Role.Admin, Role.Supervisor] }
	},
	{
		path: 'builder-jobs',
		canActivate: [AuthGuard],
		loadChildren: '@/views/builder-jobs/builder-jobs.module#BuilderJobsModule',
		data: { roles: [Role.Admin, Role.Supervisor, Role.CrewLeader] }
	},
	{
		path: 'repair-jobs',
		canActivate: [AuthGuard],
		loadChildren: '@/views/repair-jobs/repair-jobs.module#RepairJobsModule',
		data: { roles: [Role.Admin, Role.Supervisor, Role.CrewLeader] }
	},
	{
		path: 'warranty-jobs',
		canActivate: [AuthGuard],
		loadChildren: '@/views/warranty-jobs/warranty-jobs.module#WarrantyJobsModule',
		data: { roles: [Role.Admin, Role.Supervisor, Role.CrewLeader] }
	},
	{
		path: 'models',
		canActivate: [AuthGuard],
		loadChildren: '@/views/models/models.module#ModelsModule',
		data: { roles: [Role.Admin, Role.Supervisor, Role.CrewLeader] }
	},
	{
		path: 'employees',
		canActivate: [AuthGuard],
		loadChildren: '@/views/employees/employees.module#EmployeesModule',
		data: { roles: [Role.Admin, Role.Supervisor] }
	},
	{
		path: 'contacts',
		canActivate: [AuthGuard],
		loadChildren: '@/views/contacts/contacts.module#ContactsModule',
		data: { roles: [Role.Admin, Role.Supervisor] }
	},
	{
		path: 'users',
		canActivate: [AuthGuard],
		loadChildren: '@/views/users/users.module#UsersModule',
		data: { roles: [Role.Admin, Role.Supervisor] }
	},
	{
		path: 'time-entries',
		canActivate: [AuthGuard],
		loadChildren: '@/views/time/time.module#TimeModule',
		data: { roles: [Role.Admin, Role.Supervisor, Role.CrewLeader] }
	},
	{
		path: 'alerts',
		canActivate: [AuthGuard],
		loadChildren: '@/views/alerts/alerts.module#AlertsModule',
		data: { roles: [Role.Admin, Role.Supervisor, Role.CrewLeader] }
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'password-reset',
		component: PasswordResetComponent
	},
	{
		path: 'new-password',
		component: NewPasswordComponent
		},
	{
		path: '',
		redirectTo: '/dashboard',
		pathMatch: 'full'
	},
	{
		path: '',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				canActivateChild: [AuthGuard],
				children: []
			}
		]
	},
	{
		path: '**',
		component: PageNotFoundComponent
	}
];

@NgModule({
  imports: [
  	RouterModule.forRoot( routes )
  ],
  exports: [
  	RouterModule
  ],
})
export class AppRoutingModule { }
