import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {UserService} from '@/services/user/user.service';
import {AlertService} from '@/services/alert/alert.service';

@Component({
  selector: 'password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})

export class PasswordResetComponent implements OnInit {
	resetForm: FormGroup;
	submitted = false;
	userEmail:string = ''

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private alertService: AlertService
	) {
	}

  ngOnInit() {
		const urlParams = new URLSearchParams(window.location.search)
		this.userEmail  = urlParams.get('email') && urlParams.get('email')
		this.resetForm  = this.formBuilder.group({
			Email: ['', Validators.email]
		});
	}

	get form() {
		return this.resetForm.controls;
	}

	onSubmit() {
		this.submitted = true;

		if (this.resetForm.invalid) {
			return;
		}

		this.userService.resetPassword(this.resetForm.get('Email').value)
			.subscribe(
				(data: any) => {
					this.alertService.success(`Reset Password Link sent to your Email!`, true);
					this.router.navigate(['login']);
				},
				error => {
					this.alertService.error(error);

					document.body.scrollIntoView({behavior: "smooth"});
				}
			);
	}

}
